<div class="app-navbar-content" [class.bottom-menu]="isBottomMenu" [class.bottom-side-menu]="isBottomSideMenu"
    [class.collapsed]="isCollapsed">
    <ng-container *ngIf="!isBottomSideMenu">
        <ul>
            <li class="d-none d-lg-block fe-no-padding text-center mt-3 mb-5">
                <a class="nav-link" [routerLink]="'/'">
                    <img src="/assets/img/logo-full.svg" alt="Logo" width="250" *ngIf="!isCollapsed">
                    <img src="/assets/img/logo-short-black.svg" alt="Logo" width="50" *ngIf="isCollapsed">
                </a>
            </li>
        </ul>
        <ul>
            <li class="user-picture d-block fe-no-padding text-center mt-3" *ngIf="user$ | async as user"
                [routerLink]="'/dashboard/settings'">
                <img src="{{user?.avatar}}" alt="Logo" width="{{isCollapsed ? '40' : '95'}}">
                <p>{{user?.firstName}} {{user?.lastName}}</p>
            </li>
            <li class="user-profile d-block fe-no-padding text-left mt-3">
                <div [class.fe-hidden]="isCollapsed">
                    <button class="btn btn-md btn-outline btn-outline-silver text-black bg-white button-small-rounded button-xs-small"
                        [routerLink]="'/dashboard/settings/my-wallet'">
                        <div class="user-profile-content">
                            <img src="/assets/img/icons/coin.svg" width="16">
                            <span class="p-color-6">{{user?.coinBalance | number:'1.2-2'}} {{'app.nav.user-profile.coins' | translate}}</span>
                        </div>
                    </button>
                </div>
            </li>
        </ul>
    </ng-container>
    <ul class="app-navbar-menu">
        <li *ngFor="let navItem of navItemsFiltered">
            <a [routerLink]="navItem.route" [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{exact: true}"
                [class.is-active]="navItem.isChildActive" (click)="onNavigationSelection(navItem)"
                class="fe-cursor-pointer">
                <i class="{{navItem.icon}}"></i>
                <span>{{ navItem.label | translate }}</span>
            </a>
        </li>
    </ul>
</div>

<app-loader [useFullScreen]="true" *ngIf="showLoader"></app-loader>