import { Serializer } from './serializer';
import { OdometerEntryRequest } from '../resource-models/odometer-entry-request';
import * as moment from 'moment';

export class OdometerEntryRequestSerializer implements Serializer {

    fromJson(json: any): any {
        return {};
    }

    toJson(resource: OdometerEntryRequest): any {
        return {
            value: resource.odometer,
            date: moment(resource.date).format('YYYY-MM-DD'),
            car_id: resource.carId,
            notes: resource.notes
        }
    }
}
