import { Resource } from "./resource"

export class Role extends Resource {

    override id: string;
    name: string;

    constructor(
        id: string,
        name: string
    ) {
        super();
        this.id = id;
        this.name = name;
    }
}