import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { VehiclePermission } from '../resource-models/vehicle-permission';
import { VehiclePermissionItem } from '../resource-models/vehicle-permission-item';
import { VehiclePermissionItemSerializer } from './vehicle-permission-item-serializer';

export class VehiclePermissionSerializer implements Serializer {
    private arrayService = new ArrayService();
    private vehiclePermissionItemSerializer = new VehiclePermissionItemSerializer();

    fromJson(json: any): VehiclePermission {        
        return new VehiclePermission(
            this.getItem(json, 'expense'),
            this.getItem(json, 'vehicle'),
            this.getItem(json, 'technicalVisit'),
            this.getItem(json, 'odometer'),
            this.getItem(json, 'gloveBox')
        );
    }

    toJson(resource: VehiclePermission): any {
        return {
            "vehicle": [
                {
                    "update": resource.vehicle?.updateResource
                }
            ],
            "odometer": [
                {
                    "create": resource.odometer?.createResource,
                    "update": resource.odometer?.updateResource,
                    "delete": resource.odometer?.deleteResource
                }
            ],
            "expense": [
                {
                    "create": resource.expense?.createResource,
                    "read": resource.expense?.readResource,
                    "update": resource.expense?.updateResource,
                    "delete": resource.expense?.deleteResource
                }
            ],
            "technicalVisit": [
                {
                    "create": resource.technicalVisit?.createResource,
                    "read": resource.technicalVisit?.readResource,
                    "update": resource.technicalVisit?.updateResource,
                    "delete": resource.technicalVisit?.deleteResource
                }
            ],
            "gloveBox": [
                {
                    "create": resource.gloveBox?.createResource,
                    "read": resource.gloveBox?.readResource,
                    "update": resource.gloveBox?.updateResource,
                    "delete": resource.gloveBox?.deleteResource
                }
            ]
        }
    }

    private getItem(json: any, context: string): VehiclePermissionItem | null {
        const item = this.arrayService.getValue(json, context) 
        return item ? this.vehiclePermissionItemSerializer.fromJson(item) : null;
    }
    
}
