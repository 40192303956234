import { Resource } from "./resource";
import { VehiclePermission } from "./vehicle-permission";

export class VehicleDriver extends Resource {

    override id: number;
    email: string;
    firstName: string;
    lastName: string;
    permissions: VehiclePermission | null;

    constructor(
        id: number,
        email: string,
        firstName: string,
        lastName: string,
        permissions: VehiclePermission | null
    ) {
        super();
        this.id = id,
        this.email = email,
        this.firstName = firstName,
        this.lastName = lastName,
        this.permissions = permissions
    }

}