import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { TechnicalVisitItem } from '../resource-models/technical-visit-item';
import { File } from '../resource-models/file';
import { FileSerializer } from './file-serializer';
import { CurrencySerializer } from './currency-serializer';
import { Currency } from '../resource-models/currency';

export class TechnicalVisitItemSerializer implements Serializer {

    private arrayService = new ArrayService();
    private imageSerializer = new FileSerializer();
    private currencySerializer = new CurrencySerializer();

    fromJson(json: any): TechnicalVisitItem {
        return new TechnicalVisitItem(
            this.arrayService.getValue(json, 'id'),
            this.arrayService.getValue(json, 'title'),
            this.arrayService.getValue(json, 'comment'),
            this.arrayService.getValue(json, 'priceOfPart'),
            this.arrayService.getValue(json, 'priceOfWork'),
            this.getData(json),
            this.getCurrency(json)
        );
    }

    toJson(resource: TechnicalVisitItem): any {
        return {}
    }

    private getData(json: any): File[] | [] {
        const dataItemsArray = this.arrayService.getValue(json, 'images');
        return dataItemsArray === null ? [] : dataItemsArray.map((dataItemJson: any) => {
            return this.imageSerializer.fromJson(dataItemJson);
        });
    }

    private getCurrency(json: any): Currency | null {
        const dataItemJson = this.arrayService.getValue(json, 'currency');
        return dataItemJson ? this.currencySerializer.fromJson(dataItemJson) : null;
    }

}
