import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { VehicleInviteCode } from '../resource-models/vehicle-invite-code';

export class VehicleInviteCodeSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): VehicleInviteCode {
        return new VehicleInviteCode(
            this.arrayService.getValue(json, 'code'),
        );
    }

    toJson(resource: VehicleInviteCode): any {
        return {}
    }

}
