import { Serializer } from './serializer';
import { ArrayService } from '../../services/array.service';
import { RegistrationRequest } from '../resource-models/registration-request';

export class RegistrationRequestSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): RegistrationRequest {
        return new RegistrationRequest(
            this.arrayService.getValue(json, 'email'),
            this.arrayService.getValue(json, 'firstName'),
            this.arrayService.getValue(json, 'lastName'),
            this.arrayService.getValue(json, 'role'),
            this.arrayService.getValue(json, 'terms'),
            this.arrayService.getValue(json, 'newsletter'),
            this.arrayService.getValue(json, 'deviceId'),
        );
    }

    toJson(resource: RegistrationRequest): any {
        return {
            email: resource.email,
            first_name: resource.first_name,
            last_name: resource.last_name,
            role: resource.role,
            terms: resource.terms,
            newsletter: resource.newsletter,
            device_id: resource.deviceId
        }
    }
}
