import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LangSwitcherService {

    public static readonly defaultLang: string = 'en';

    public static readonly supportedLanguages: Array<{ key: string, value: string }> = [
        { key: 'bs', value: 'Bosanski' },
        { key: 'sr', value: 'Srpski' },
        { key: 'de', value: 'Deutsch' },
        { key: 'en', value: 'English' },
        { key: 'hr', value: 'Hrvatski' },
    ];

    public static setUserSelectedLang(lang: string): void {
        if (!LangSwitcherService.isLangSupported(lang)) {
            localStorage.setItem('selected_language', this.defaultLang);
            return;
        }
        localStorage.setItem('selected_language', lang);
    }

    public static getUserSelectedLang(): string | undefined | null  {
        const lang = localStorage.getItem('selected_language');
        if (!lang) {
            return;
        }
        if (!LangSwitcherService.isLangSupported(lang)) {
            return;
        }
        return lang;
    }

    public static isLangSupported(lang: string): boolean {
        if (LangSwitcherService.supportedLanguages.find(language => language.key === lang) === undefined) {
            return false;
        }
        return true;
    }

}
