import { ArrayService } from 'src/app/services/array.service';
import { Serializer } from './serializer';
import { BearerToken } from '../resource-models/bearer-token';

export class BearerTokenSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): BearerToken {
        return new BearerToken(
            this.arrayService.getValue(json, 'access_token')
        );
    }

    toJson(resource: BearerToken): any {
        return {}
    }
}
