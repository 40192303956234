import {Serializer} from './serializer';
import { User } from '../resource-models/user';
import { UserSerializer } from './user-serializer';
import { UserUpdateRequest } from '../resource-models/user-update-request';

export class UserUpdateRequestSerializer implements Serializer {
    private userSerializer = new UserSerializer();

    fromJson(json: any): User {
        return this.userSerializer.fromJson(json);
    }

    toJson(resource: UserUpdateRequest): any {
        return {
            first_name: resource.firstName,
            last_name: resource.lastName,
            country_id: resource.countryId
        }
    }

}
