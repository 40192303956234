import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import {VehicleExpenseMeta} from "../resource-models/vehicle-expense-meta";

export class VehicleExpenseMetaSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): VehicleExpenseMeta {
        return new VehicleExpenseMeta(
            this.arrayService.getValue(json, 'fuel_price'),
            this.arrayService.getValue(json, 'fuel_quantity'),
            this.arrayService.getValue(json, 'avg_fuel_consumption')
        );
    }

    toJson(resource: VehicleExpenseMeta): any {
        return {}
    }

}
