import {Serializer} from './serializer';
import { UserDeleteRequest } from '../resource-models/user-delete-request';

export class UserDeleteRequestSerializer implements Serializer {

    fromJson(json: any): any {
        return {};
    }

    toJson(resource: UserDeleteRequest): any {
        return {
            password: resource.password
        }
    }
}
