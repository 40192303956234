import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { Paginator } from '../resource-models/paginator';
import { PaginatorSerializer } from './paginator-serializer';
import { VehicleHistoryEntrySerializer } from './vehicle-history-entry-serializer';
import { VehicleHistoryEntryPaginate } from '../resource-models/vehicle-history-entry-paginate';
import { VehicleHistoryEntry } from '../resource-models/vehicle-history-entry';

export class VehicleHistoryEntryPaginateSerializer implements Serializer {
    private arrayService = new ArrayService();
    private vehicleHistorySerializer = new VehicleHistoryEntrySerializer();
    private paginatorSerializer = new PaginatorSerializer();

    fromJson(json: any): VehicleHistoryEntryPaginate {
        return new VehicleHistoryEntryPaginate(
            this.getData(json),
            this.getPaginateData(json)
        );
    }

    toJson(resource: VehicleHistoryEntryPaginate): any {
        return {}
    }

    private getData(json: any): VehicleHistoryEntry[] | [] {
        const dataItemsArray = this.arrayService.getValue(json, 'data');
        return dataItemsArray === null ? [] : dataItemsArray.map((dataItemJson: any) => {
            return this.vehicleHistorySerializer.fromJson(dataItemJson);
        });
    }

    private getPaginateData(json: any): Paginator | null {
        const dataItemJson = this.arrayService.getValue(json, 'meta');
        return dataItemJson ? this.paginatorSerializer.fromJson(dataItemJson) : null;
    }

}
