import { Serializer } from './serializer';
import { ArrayService } from '../../services/array.service';
import { VehicleRequest } from '../resource-models/vehicle-request';

export class VehicleRequestSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): VehicleRequest {
        return new VehicleRequest(
            this.arrayService.getValue(json, 'car_model_id'),
            this.arrayService.getValue(json, 'name'),
            this.arrayService.getValue(json, 'year'),
            this.arrayService.getValue(json, 'power'),
            this.arrayService.getValue(json, 'vin'),
            this.arrayService.getValue(json, 'fuel'),
            this.arrayService.getValue(json, 'plates'),
            this.arrayService.getValue(json, 'last_registered'),
            this.arrayService.getValue(json, 'type')
        );
    }

    toJson(resource: VehicleRequest): any {
        return {
            car_model_id: resource.carModelId,
            name: resource.name,
            year: resource.year,
            power: resource.power,
            vin: resource.vin,
            fuel: resource.fuel,
            plates: resource.plates,
            last_registered: resource.lastRegistered,
            type: resource.type,
            favorite: resource.favorite
        }
    }
}
