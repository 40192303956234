import { Paginator } from "./paginator";
import { Resource } from "./resource";
import { VehicleExpense } from "./vehicle-expense";

export class VehicleExpensePaginate extends Resource {

    data: VehicleExpense[];
    paginator: Paginator | null;

    constructor(
        data: VehicleExpense[],
        paginator: Paginator | null
    ) {
        super();
        this.data = data;
        this.paginator = paginator;
    }

}