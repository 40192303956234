import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Component({
    selector: 'app-empty-layout',
    templateUrl: './empty-layout.component.html',
    styleUrls: ['../app-layout/app-layout.component.scss', './empty-layout.component.scss']
})
export class EmptyLayoutComponent implements OnInit, OnDestroy {

    constructor(
        private renderer: Renderer2
    ) { }

    ngOnInit(): void {
        this.renderer.setStyle(document.body, 'background-color', '#fff');
    }

    ngOnDestroy(): void {
        this.renderer.setStyle(document.body, 'background-color', 'unset');
    }

}
