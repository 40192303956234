import { Serializer } from './serializer';
import { ArrayService } from '../../services/array.service';
import { ChartExpenseData } from '../resource-models/chart-expense-data';

export class ChartExpenseDataSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): ChartExpenseData {
        return new ChartExpenseData(
            this.arrayService.getValue(json, 'name'),
            this.arrayService.getValue(json, 'value'),
            this.arrayService.getValue(json, 'color'),
            this.arrayService.getValue(json, 'expenseTypeId')
        );
    }

    toJson(resource: ChartExpenseData): any {
        return {}
    }
}
