import { Injectable, Injector } from '@angular/core';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

declare global {
    interface Window {
        Pusher: any;
    }
}

@Injectable({
    providedIn: 'root',
})
export class EchoService {
    public echo: Echo<any> | undefined;
    private authService: AuthService | undefined;

    constructor(private injector: Injector) {}

    private initializeEcho(): void {
        if (this.echo) return;
        window.Pusher = Pusher;

        this.authService = this.injector.get(AuthService);
        const token = this.authService.getAuthKey();
        if (!token) {
            return;
        }
        console.log(token);
        
        this.echo = new Echo({
            authEndpoint: environment.apiBaseUrl + '/broadcasting/auth',
            broadcaster: 'reverb',
            key: environment.reverbKey,
            wsHost: environment.reverbHost,
            wsPort: environment.reverbPort,
            wssPort: environment.reverbPort,
            forceTLS: environment.reverbScheme === 'https',
            enabledTransports: ['ws', 'wss'],
            disableStats: true,
            cluster: '',
            encrypted: true,
            auth: {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        });
    }

    listen(channel: string, event: string, callback: (data: any) => void) {
        this.initializeEcho();
        this.echo
            ?.channel(channel)
            .listen(event, (data: any) => callback(data));
    }

    private(channel: string, event: string, callback: (data: any) => void) {
        this.initializeEcho();
        this.echo
            ?.private(channel)
            .listen(event, (data: any) => callback(data));
    }

    leave(channel: string) {
        this.echo?.leave(channel);
        this.echo = undefined;
    }

    disconnect() {
        this.echo?.disconnect();
    }
}
