import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { VehicleBrand } from '../resource-models/vehicle-brand';
import { environment } from 'src/environments/environment';

export class VehicleBrandSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): VehicleBrand {
        return new VehicleBrand(
            this.arrayService.getValue(json, 'id'),
            this.arrayService.getValue(json, 'title'),
            this.getLogo(json)
        );
    }

    toJson(resource: VehicleBrand): any {
        return {
            title: resource.title
        }
    }

    private getLogo(json: any): string {
        const logo = this.arrayService.getValue(json, 'logo');
        if (logo) {
            return environment.apiBaseUrl + logo;
        }
        return '';
    }

}
