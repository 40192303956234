<div ngbDropdown class="d-inline-block">
    <button type="button" class="btn btn-primary text-uppercase btn-sm" id="dropdownBasic1" ngbDropdownToggle>
        <i class="icon-network"></i>
        {{currentLanguage?.key}}
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <ng-container *ngFor="let lang of supportedLanguages">
            <ng-container *ngIf="lang.key !== currentLanguage?.key">
                <button ngbDropdownItem (click)="switch(lang.key)">
                    {{lang.value}}
                </button>
            </ng-container>
        </ng-container>
    </div>
</div>