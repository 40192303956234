import {Serializer} from './serializer';
import { VehicleModelRequest } from '../resource-models/vehicle-model-request';

export class VehicleModelRequestSerializer implements Serializer {

    fromJson(json: any): any {
        return {};
    }

    toJson(resource: VehicleModelRequest): any {
        return {
            title: resource.title,
            car_brand_id: resource.carBrandId
        }
    }
}
