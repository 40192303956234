import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangSwitcherService } from 'src/app/services/lang-switcher.service';

@Component({
    selector: 'app-lang-switcher',
    templateUrl: './lang-switcher.component.html',
    styleUrls: ['./lang-switcher.component.scss']
})
export class LangSwitcherComponent implements OnInit {

    private userSelectedLanguage: string | undefined | null = LangSwitcherService.getUserSelectedLang();
    private userBrowserLanguage: string | undefined;
    supportedLanguages: Array<{ key: string, value: string }> = LangSwitcherService.supportedLanguages;
    currentLanguage: { key: string, value: string } | undefined;

    constructor(
        private translate: TranslateService
    ) {
        this.userBrowserLanguage = this.translate.getBrowserLang();
        this.preselectLang();
    }

    ngOnInit(): void {
    }

    private preselectLang(): void {
        if (this.userSelectedLanguage) {
            this.switch(this.userSelectedLanguage);
            return;
        }
        if (this.userBrowserLanguage && LangSwitcherService.isLangSupported(this.userBrowserLanguage)) {
            this.switch(this.userBrowserLanguage);
        }
    }

    switch(lang: string) {
        if (!LangSwitcherService.isLangSupported(lang)) {
            return;
        }
        this.translate.use(lang);
        this.translate.setDefaultLang(lang);
        LangSwitcherService.setUserSelectedLang(lang);
        this.setCurrentLang(lang);
    }

    private setCurrentLang(lang: string) {
        const language = this.supportedLanguages.find(x => x.key === lang);
        this.currentLanguage = language;
    }

}
