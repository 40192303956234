import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ValidationMessage } from '../models/resource-models/validation-message';
import { ValidationMessageSerializer } from '../models/serializers/validation-message-serializer';
import { ArrayService } from './array.service';
import { ErrorHandlerInterface } from './interfaces/error-handler-interface';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {

    protected arrayService = new ArrayService();
    protected validationMessageSerializer = new ValidationMessageSerializer();
    private falbackErrorMessage = 'An error has occured! Try again a bit later please.';

    constructor() {
    }

    public setMessages(error: any): ErrorHandlerInterface {
        // in case endpoint returns no response body
        if (error.error === 'undefined' || error.error === null || error.error === undefined) {
            return {
                status: null,
                code: null,
                datetime: null,
                timestamp: null,
                message: this.falbackErrorMessage,
                details: null,
                userMessages: []
            };
        }
        return {
            status: error.status,
            code: error.error.code || null,
            datetime: error.error.datetime || null,
            timestamp: error.error.timestamp || null,
            message: error.error.message || this.setCodeSpecificErrorMessage(error),
            details: error.error.details || null,
            userMessages: this.getUserMessages(error.error)
        };
    }

    private getUserMessages(json: any): ValidationMessage[] | any {
        const errorsObject = this.arrayService.getValue(json, 'errors');
        let messagesArray: any = [];        
        if (errorsObject) {
            for (const field in errorsObject) {
                if (Object.prototype.hasOwnProperty.call(errorsObject, field)) {
                    const message = errorsObject[field][0];
                    messagesArray.push({field: field, message: message});
                }
            }
        }
        return messagesArray === null ? [] : messagesArray.map((messageItemJson: any) => {
            return this.validationMessageSerializer.fromJson(messageItemJson);
        });
    }

    public displayValidationMessages(
        fg: UntypedFormGroup, messages: ValidationMessage[],
        fallbackControls?: Array<{ key: string, value: string }>) {
        return messages.forEach(message => {
            const controlName = fg.controls[message.field] !== undefined
                ? message.field : this.getFallbackControlName(message.field, fallbackControls);
            if (fg.controls[controlName] !== undefined) {
                return fg.controls[controlName].setErrors(
                    { 'customError': message.message }
                );
            }
        });
    }

    private getFallbackControlName(messageField: string, fallbackControls: any): string {
        if (fallbackControls === undefined) {
            return messageField;
        }
        const controlName = fallbackControls.find((control: any) => {
            return messageField === control.key;
        });
        return controlName !== undefined ? controlName.value : '';
    }

    private setCodeSpecificErrorMessage(error: any): string {
        if(error?.status === 403 || error?.error?.code === '403') {
            return 'Error: Action forbidden';
        }
        return this.falbackErrorMessage;
    }

    public getFirstUserMessage(error: any): string {
        const messages = this.setMessages(error);
        return !messages.userMessages.length ? (messages.message || this.falbackErrorMessage) : messages.userMessages[0].message || this.falbackErrorMessage;
    }

    public getFirstMessageField(error: any): string {
        const messages = this.setMessages(error);
        return !messages.userMessages.length ? '' : messages.userMessages[0].field;
    }

    public isRecaptchaError(error: any): boolean {
        return this.getFirstMessageField(error) === 'recaptcha';
    }

    public isErrorCode(error: any, code: string): boolean {
        return this.setMessages(error).code === code;
    }

    public isStatusCode(error: any, statusCode: number): boolean {
        return this.setMessages(error).status === statusCode;
    }

}
