import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { ValidationMessage } from '../resource-models/validation-message';

export class ValidationMessageSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): ValidationMessage {
        return new ValidationMessage(
            this.arrayService.getValue(json, 'field'),
            this.arrayService.getValue(json, 'message')
        );
    }

    toJson(resource: ValidationMessage): any {
    }
}
