import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { VehicleExpense } from '../resource-models/vehicle-expense';
import { ExpenseType } from '../resource-models/expense-type';
import { ExpenseTypeSerializer } from './expense-type-serializer';
import { FileSerializer } from './file-serializer';
import { File } from '../resource-models/file';
import { Vehicle } from '../resource-models/vehicle';
import { VehicleSerializer } from './vehicle-serializer';
import * as moment from 'moment';
import {VehicleExpenseMeta} from "../resource-models/vehicle-expense-meta";
import {VehicleExpenseMetaSerializer} from "./vehicle-expense-meta-serializer";
import {CurrencySerializer} from "./currency-serializer";
import {Currency} from "../resource-models/currency";

export class VehicleExpenseSerializer implements Serializer {
    private arrayService = new ArrayService();
    private expenseTypeSerializer = new ExpenseTypeSerializer();
    private imageSerializer = new FileSerializer();
    private vehicleSerializer = new VehicleSerializer();
    private expenseMetaSerializer = new VehicleExpenseMetaSerializer();
    private currencySerializer = new CurrencySerializer();

    fromJson(json: any): VehicleExpense {
        return new VehicleExpense(
            this.arrayService.getValue(json, 'id'),
            this.arrayService.getValue(json, 'price'),
            this.arrayService.getValue(json, 'comment'),
            this.getDate(json),
            this.getExpenseType(json),
            this.getImages(json),
            this.getVehicle(json),
            this.getMetaData(json),
            this.arrayService.getValue(json, 'odometer'),
            this.getCurrency(json),
        );
    }

    toJson(resource: VehicleExpense): any {
        return {}
    }

    private getDate(json: any): string {
        let date = this.arrayService.getValue(json, 'dateOfWork');
        return date;
        return date ? moment(date).format('DD.MM.YYYY H:m') : '';
    }

    private getExpenseType(json: any): ExpenseType | null {
        const dataItemJson = this.arrayService.getValue(json, 'expenseType');
        return dataItemJson ? this.expenseTypeSerializer.fromJson(dataItemJson) : null;
    }

    private getImages(json: any): File[] | [] {
        const dataItemsArray = this.arrayService.getValue(json, 'images');
        return dataItemsArray === null ? [] : dataItemsArray.map((dataItemJson: any) => {
            return this.imageSerializer.fromJson(dataItemJson);
        });
    }

    private getVehicle(json: any): Vehicle | null {
        const dataItemJson = this.arrayService.getValue(json, 'vehicle');
        return dataItemJson ? this.vehicleSerializer.fromJson(dataItemJson) : null;
    }

    private getMetaData(json: any): VehicleExpenseMeta | undefined {
        const dataItemJson = this.arrayService.getValue(json, 'meta');
        return dataItemJson ? this.expenseMetaSerializer.fromJson(dataItemJson) : undefined;
    }

    private getCurrency(json: any): Currency | null {
        const dataItemJson = this.arrayService.getValue(json, 'currency');
        return dataItemJson ? this.currencySerializer.fromJson(dataItemJson) : null;
    }

}
