import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { Country } from '../resource-models/country';

export class CountrySerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): Country {
        return new Country(
            this.arrayService.getValue(json, 'id'),
            this.arrayService.getValue(json, 'name'),
            this.arrayService.getValue(json, 'native'),
            this.arrayService.getValue(json, 'code'),
        );
    }

    toJson(resource: Country): any {
    }
}
