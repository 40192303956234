<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <div class="content">
                <div class="lottie-container" [style]="{'width': '350px', 'margin': '50px auto'}">
                    <ng-lottie [options]="noDataLottieOptions" [width]="'350px'"></ng-lottie>
                </div>
                <div class="text-center">
                    <button class="btn fe-btn btn-primary" (click)="goBack()">
                        <i class="icon-arrow-left2"></i>&nbsp; {{'general.btn-back' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>