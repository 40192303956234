import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

    noDataLottieOptions: AnimationOptions = {
        path: '/assets/animations/error-404.json'
    };

    constructor(
        private location: Location
    ) { }

    ngOnInit(): void {
    }

    goBack() {
        this.location.back();
    }

}
