import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-site-nav-content',
    templateUrl: './site-nav-content.component.html',
    styleUrls: ['./site-nav-content.component.scss']
})
export class SiteNavContentComponent implements OnInit {

    navItems = [
        { label: 'nav.main.home', route: '/' },
        { label: 'nav.main.login', route: '/auth/login' },
        { label: 'nav.main.register', route: '/auth/signup' }
    ];

    constructor(
        private router: Router
    ) { }

    ngOnInit(): void {
    }

    onNavigationSelection(navItem: any) {
        this.router.navigate([navItem.route]);
    }

}
        