import { Resource } from "./resource";

export class Paginator extends Resource {
    currentPage: number;
    from: number;
    lastPage: number;
    perPage: number;
    to: number;
    total: number;

    constructor(
        currentPage: number,
        from: number,
        lastPage: number,
        perPage: number,
        to: number,
        total: number
    ) {
        super();
        this.currentPage = currentPage;
        this.from = from;
        this.lastPage = lastPage;
        this.perPage = perPage;
        this.to = to;
        this.total = total;
    }

}