import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { VehicleModel } from '../resource-models/vehicle-model';

export class VehicleModelSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): VehicleModel {
        return new VehicleModel(
            this.arrayService.getValue(json, 'id'),
            this.arrayService.getValue(json, 'title')
        );
    }

    toJson(resource: VehicleModel): any {
        return {
            title: resource.title
        }
    }
}
