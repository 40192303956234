import { Resource } from "./resource"

export class ChartExpenseData extends Resource {

    name: string;
    value: number;
    color: string;
    expenseTypeId: number;
    startValue?: number;
    endValue?: number;

    constructor(
        name: string,
        value: number,
        color: string,
        expenseTypeId: number,
        startValue?: number,
        endValue?: number
    ) {
        super();
        this.name = name;
        this.value = value;
        this.color = color;
        this.expenseTypeId = expenseTypeId;
        this.startValue = startValue;
        this.endValue = endValue;
    }
}