import { NgModule } from '@angular/core';
import { HasVehiclePermissionDirective } from '../directives/has-vehicle-permission.directive';
import { CopyToClipboardDirective } from '../directives/copy-to-clipboard.directive';
import { IsMobileDirective } from '../directives/is-mobile.directive';
import { IsDesktopDirective } from '../directives/is-desktop.directive';
import { FocusOnClickDirective } from '../directives/focus-on-click.directive';

@NgModule({
    declarations: [
        HasVehiclePermissionDirective,
        CopyToClipboardDirective,
        IsMobileDirective,
        IsDesktopDirective,
        FocusOnClickDirective
    ],
    imports: [],
    exports: [
        HasVehiclePermissionDirective,
        CopyToClipboardDirective,
        IsMobileDirective,
        IsDesktopDirective,
        FocusOnClickDirective
    ]
})
export class SharedDirectiveModule { }
