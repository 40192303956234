export class NumberHelper {
    public static calculatePercentage(
        averageValue: number,
        maxPossibleValue: number
    ): number {
        if (
            !averageValue ||
            averageValue <= 0 ||
            !maxPossibleValue ||
            maxPossibleValue <= 0
        ) {
            return 0;
        }
        return Math.round((averageValue / maxPossibleValue) * 100);
    }

    public static minMaxNormalization(value: number, min: number, max: number): number {
        return (value - min) / (max - min);
      }
    
}
