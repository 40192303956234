import { Resource } from "./resource";

export class RegistrationRequest extends Resource {

    email: string;
    first_name: string;
    last_name: string;
    role: string;
    terms: boolean;
    newsletter: boolean;
    deviceId?: string | null;

    constructor(
        email: string,
        first_name: string,
        last_name: string,
        role: string,
        terms: boolean,
        newsletter: boolean,
        deviceId?: string | null
    ) {
        super();
        this.email = email;
        this.first_name = first_name;
        this.last_name = last_name;
        this.role = role;
        this.terms = terms;
        this.newsletter = newsletter;
        this.deviceId = deviceId;
    }

}