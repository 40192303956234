import { Serializer } from './serializer';
import { ArrayService } from '../../services/array.service';
import { VehicleType } from '../resource-models/vehicle-type';

export class VehicleTypeSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): VehicleType {
        return new VehicleType(
            this.arrayService.getValue(json, 'key'),
            this.arrayService.getValue(json, 'name')
        );
    }

    toJson(resource: VehicleType): any {
        return {
            key: resource.key
        }
    }
}
