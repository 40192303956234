import { Paginator } from "./paginator";
import { Resource } from "./resource";
import { VehicleHistoryEntry } from "./vehicle-history-entry";

export class VehicleHistoryEntryPaginate extends Resource {

    data: VehicleHistoryEntry[];
    paginator: Paginator | null;

    constructor(
        data: VehicleHistoryEntry[],
        paginator: Paginator | null
    ) {
        super();
        this.data = data;
        this.paginator = paginator;
    }

}