import { ArrayService } from 'src/app/services/array.service';
import { Serializer } from './serializer';
import { OneTimeToken } from '../resource-models/one-time-token';

export class OneTimeTokenSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): OneTimeToken {
        return new OneTimeToken(
            this.arrayService.getValue(json, 'token')
        );
    }

    toJson(resource: OneTimeToken): any {
        return {}
    }
}
