import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
    selector: 'app-focused-layout',
    templateUrl: './focused-layout.component.html',
    styleUrls: [
        '../app-layout/app-layout.component.scss',
        './focused-layout.component.scss',
    ],
})
export class FocusedLayoutComponent implements OnInit, OnDestroy {
    hasBckgImage = false;
    hasBackBtn$: Observable<boolean> = this.navService.focusedLayoutBackBtn$;

    constructor(
        private route: ActivatedRoute,
        private renderer: Renderer2,
        private navService: NavigationService,
        private location: Location
    ) {
    }

    ngOnInit(): void {
        this.renderer.setStyle(document.body, 'background-color', '#fff');
        this.route.data.subscribe((data) => {
            this.hasBckgImage = data['hasBckgImage'];
        });
    }

    ngOnDestroy(): void {
        this.renderer.setStyle(document.body, 'background-color', 'unset');
    }

    goBack() {
        this.location.back();
    }
}
