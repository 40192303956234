import { Resource } from "./resource";

export class VehicleRequest extends Resource {
    carModelId: number;
    name: string;
    year: string;
    power: string;
    vin: string | null;
    fuel: string;
    plates: string;
    lastRegistered: string | null;
    type: string;
    favorite?: boolean;

    constructor(
        carModelId: number,
        name: string,
        year: string,
        power: string,
        vin: string | null,
        fuel: string,
        plates: string,
        lastRegistered: string | null,
        type: string,
        favorite?: boolean
    ) {
        super();
        this.carModelId = carModelId;
        this.name = name;
        this.year = year;
        this.power = power;
        this.vin = vin;
        this.fuel = fuel;
        this.plates = plates;
        this.lastRegistered = lastRegistered;
        this.type = type;
        this.favorite = favorite;
    }

}