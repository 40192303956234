import { Resource } from "./resource";

export class VehicleTransfersOverview extends Resource {
    override id: number;
    technicalVisitLogs: number;
    odometerLogs: number;
    expenseLogs: number;

    constructor(
        id: number,
        technicalVisitLogs: number,
        odometerLogs: number,
        expenseLogs: number
    ) {
        super();
        this.id = id;
        this.technicalVisitLogs = technicalVisitLogs;
        this.odometerLogs = odometerLogs;
        this.expenseLogs = expenseLogs;
    }

}