import {Serializer} from './serializer';
import { EmailResendRequest } from '../resource-models/email-resend-request';

export class EmailResendRequestSerializer implements Serializer {

    fromJson(json: any): any {
        return {};
    }

    toJson(resource: EmailResendRequest): any {
        return {
            email: resource.email,
            user_id: resource.userId
        }
    }
}
