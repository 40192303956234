import {Serializer} from './serializer';
import { VehicleBrandRequest } from '../resource-models/vehicle-brand-request';

export class VehicleBrandRequestSerializer implements Serializer {

    fromJson(json: any): any {
        return {};
    }

    toJson(resource: VehicleBrandRequest): any {
        return {
            title: resource.title
        }
    }
}
