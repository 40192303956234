import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowErrorsComponent } from './validators/show-errors/show-errors.component';
import { TranslateModule } from '@ngx-translate/core';
import { LangSwitcherComponent } from './lang-switcher/lang-switcher.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SideNavComponent } from './side-nav/side-nav.component';
import { SiteNavContentComponent } from './site-nav-content/site-nav-content.component';
import { AppNavContentComponent } from './app-nav-content/app-nav-content.component';
import { RouterModule } from '@angular/router';
import { ProgressHeaderComponent } from './progress-header/progress-header.component';
import { AddNewVehicleComponent } from './add-new-vehicle/add-new-vehicle.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoaderComponent } from './loader/loader.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartExpenseTotalComponent } from './chart-expense-total/chart-expense-total.component';
import { ChartExpensePerCategoryComponent } from './chart-expense-per-category/chart-expense-per-category.component';
import { ChartOdometerComponent } from './chart-odometer/chart-odometer.component';
import { EditOdometerComponent } from './edit-odometer/edit-odometer.component';
import { NgxMaskModule } from 'ngx-mask';
import { ChartOdometerHistoryComponent } from './chart-odometer-history/chart-odometer-history.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { VehicleBoxComponent } from './vehicle-box/vehicle-box.component';
import { CustomValidatorService } from './validators/custom-validator-service';
import { ExpenseCarouselComponent } from './expense-carousel/expense-carousel.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { PipeModule } from '../pipe/pipe.module';
import { BottomMenuComponent } from './bottom-menu/bottom-menu.component';
import { VehicleTimelineComponent } from './vehicle-timeline/vehicle-timeline.component';
import { VehicleLogoInfoComponent } from './vehicle-logo-info/vehicle-logo-info.component';
import { ImagePopupComponent } from './image-popup/image-popup.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ClickStopPropagationDirective } from '../directives/click-stop-propagation.directive';
import { ExpenseDetailsBoxComponent } from './expense-details-box/expense-details-box.component';
import { SharedDirectiveModule } from '../shared-directive/shared-directive.module';
import { LottieModule } from 'ngx-lottie';
import { OnboardingIntroBckgModule } from "../partials/onboarding-intro-bckg/onboarding-intro-bckg.module";
import { VehiclePickerComponent } from './vehicle-picker/vehicle-picker.component';
import { BuyNewVehicleModalComponent } from './buy-new-vehicle-modal/buy-new-vehicle-modal.component';

@NgModule({
    declarations: [
        ShowErrorsComponent,
        LangSwitcherComponent,
        SideNavComponent,
        SiteNavContentComponent,
        AppNavContentComponent,
        ProgressHeaderComponent,
        AddNewVehicleComponent,
        LoaderComponent,
        UpdateProfileComponent,
        ChartExpenseTotalComponent,
        ChartExpensePerCategoryComponent,
        ChartOdometerComponent,
        EditOdometerComponent,
        ChartOdometerHistoryComponent,
        VehicleBoxComponent,
        ExpenseCarouselComponent,
        ConfirmDeleteComponent,
        BottomMenuComponent,
        BuyNewVehicleModalComponent,
        VehicleTimelineComponent,
        VehicleLogoInfoComponent,
        ImagePopupComponent,
        ClickStopPropagationDirective,
        ExpenseDetailsBoxComponent,
        VehiclePickerComponent
    ],
    exports: [
        ShowErrorsComponent,
        LangSwitcherComponent,
        SideNavComponent,
        SiteNavContentComponent,
        AppNavContentComponent,
        ProgressHeaderComponent,
        AddNewVehicleComponent,
        LoaderComponent,
        UpdateProfileComponent,
        ChartExpenseTotalComponent,
        ChartExpensePerCategoryComponent,
        ChartOdometerComponent,
        EditOdometerComponent,
        ChartOdometerHistoryComponent,
        VehicleBoxComponent,
        ExpenseCarouselComponent,
        ConfirmDeleteComponent,
        BottomMenuComponent,
        BuyNewVehicleModalComponent,
        VehicleTimelineComponent,
        VehicleLogoInfoComponent,
        ImagePopupComponent,
        ClickStopPropagationDirective,
        ExpenseDetailsBoxComponent,
        VehiclePickerComponent
    ],
    providers: [
        CustomValidatorService
    ],
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        ReactiveFormsModule,
        NgSelectModule,
        FormsModule,
        CarouselModule,
        NgxChartsModule,
        NgxMaskModule.forRoot(),
        FileUploadModule,
        TranslateModule,
        PipeModule,
        PdfViewerModule,
        SharedDirectiveModule,
        LottieModule,
        OnboardingIntroBckgModule
    ]
})
export class SharedModule { }
