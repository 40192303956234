import {Serializer} from './serializer';
import {Empty} from '../resource-models/empty';

export class EmptySerializer implements Serializer {
    fromJson(json: any): Empty {
        return new Empty();
    }
    toJson(resource: Empty): any {
        return {};
    }

}
