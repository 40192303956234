import { Resource } from "./resource";
import { VehicleBrand } from "./vehicle-brand";
import { VehicleModel } from "./vehicle-model";
import { VehiclePermission } from "./vehicle-permission";

export class Vehicle extends Resource {
    override id: number;
    name: string;
    brand: VehicleBrand | null;
    model: VehicleModel | null;
    year: number;
    power: string;
    vin: string;
    fuel: string;
    plates: string;
    lastRegistered: string;
    isFavorite: boolean;
    odometer: number;
    odometerLastUpdateDate: string;
    isOwner: boolean;
    createdAt: string;
    fuelSlug: 'diesel' | 'gasoline' | 'hybrid' | 'electric';
    driversCode: string;
    driversDefaultPermissions: VehiclePermission | null;
    permissions: VehiclePermission | null;
    type: string;
    typeSlug: 'car' | 'electric_scooter' | 'van' | 'truck' | 'motorbike';
    maxNumberOfDrivers: number;

    constructor(
        id: number,
        name: string,
        brand: VehicleBrand | null,
        model: VehicleModel | null,
        year: number,
        power: string,
        vin: string,
        fuel: string,
        plates: string,
        lastRegistered: string,
        isFavorite: boolean,
        odometer: number,
        odometerLastUpdateDate: string,
        isOwner: boolean,
        createdAt: string,
        fuelSlug: 'diesel' | 'gasoline' | 'hybrid' | 'electric',
        driversCode: string,
        driversDefaultPermissions: VehiclePermission | null,
        permissions: VehiclePermission | null,
        type: string,
        typeSlug: 'car' | 'electric_scooter' | 'van' | 'truck' | 'motorbike',
        maxNumberOfDrivers: number
    ) {
        super();
        this.id = id;
        this.name = name;
        this.brand = brand;
        this.model = model;
        this.year = year;
        this.power = power;
        this.vin = vin;
        this.fuel = fuel;
        this.plates = plates;
        this.lastRegistered = lastRegistered;
        this.isFavorite = isFavorite;
        this.odometer = odometer;
        this.odometerLastUpdateDate = odometerLastUpdateDate;
        this.isOwner = isOwner;
        this.createdAt = createdAt;
        this.fuelSlug = fuelSlug;
        this.driversCode = driversCode;
        this.driversDefaultPermissions = driversDefaultPermissions;
        this.permissions = permissions;
        this.type = type;
        this.typeSlug = typeSlug;
        this.maxNumberOfDrivers = maxNumberOfDrivers;
    }

}