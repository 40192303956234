import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingIntroBckgComponent } from './onboarding-intro-bckg.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    OnboardingIntroBckgComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    OnboardingIntroBckgComponent
  ]
})
export class OnboardingIntroBckgModule { }
