import { Resource } from "./resource";
import { User } from "./user";
import { Vehicle } from "./vehicle";
import { VehiclePermission } from "./vehicle-permission";

export class VehicleDriverInvite extends Resource {
    override id: number;
    code: string;
    email: string;
    firstName: string;
    lastName: string | null;
    permissions: VehiclePermission | null;
    vehicle: Vehicle | null;
    owner: User | null;
    accessToken: string | null;

    constructor(
        id: number,
        code: string,
        email: string,
        firstName: string,
        lastName: string | null,
        permissions: VehiclePermission | null,
        vehicle: Vehicle | null,
        owner: User | null,
        accessToken: string | null
    ) {
        super();
        this.id = id;
        this.code = code;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.permissions = permissions;
        this.vehicle = vehicle;
        this.owner = owner;
        this.accessToken = accessToken;
    }

}