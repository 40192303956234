import { Serializer } from './serializer';
import { VehicleDriverInviteRequest } from '../resource-models/vehicle-driver-invite-request';

export class VehicleDriverInviteRequestSerializer implements Serializer {

    fromJson(json: any): any {
        return {}
    }

    toJson(resource: VehicleDriverInviteRequest): any {
        return {
            email: resource.email,
            first_name: resource.firstName,
            last_name: resource.lastName,
            code: resource.code,
            permissions: {
                "vehicle": [
                    {
                        "update": resource.permissions?.vehicle?.updateResource || false
                    }
                ],
                "odometer": [
                    {
                        "update": resource.permissions?.odometer?.updateResource || false,
                        "create": resource.permissions?.odometer?.createResource || false,
                        "read": resource.permissions?.odometer?.readResource || false,
                        "delete": resource.permissions?.odometer?.deleteResource || false
                    }
                ],
                "expense": [
                    {
                        "update": resource.permissions?.expense?.updateResource || false,
                        "create": resource.permissions?.expense?.createResource || false,
                        "read": resource.permissions?.expense?.readResource || false,
                        "delete": resource.permissions?.expense?.deleteResource || false
                    }
                ],
                "technicalVisit": [
                    {
                        "update": resource.permissions?.technicalVisit?.updateResource || false,
                        "create": resource.permissions?.technicalVisit?.createResource || false,
                        "read": resource.permissions?.technicalVisit?.readResource || false,
                        "delete": resource.permissions?.technicalVisit?.deleteResource || false
                    }
                ],
                "gloveBox": [
                    {
                        "update": resource.permissions?.gloveBox?.updateResource || false,
                        "create": resource.permissions?.gloveBox?.createResource || false,
                        "read": resource.permissions?.gloveBox?.readResource || false,
                        "delete": resource.permissions?.gloveBox?.deleteResource || false
                    }
                ]
            }


        }
    }

}
