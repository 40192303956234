import { Serializer } from './serializer';
import { ArrayService } from '../../services/array.service';
import { VehicleDriverInvite } from '../resource-models/vehicle-driver-invite';
import { VehiclePermission } from '../resource-models/vehicle-permission';
import { VehiclePermissionSerializer } from './vehicle-permission-serializer';
import { Vehicle } from '../resource-models/vehicle';
import { VehicleSerializer } from './vehicle-serializer';
import { User } from '../resource-models/user';
import { UserSerializer } from './user-serializer';

export class VehicleDriverInviteSerializer implements Serializer {
    private arrayService = new ArrayService();
    private vehiclePermissionSerializer = new VehiclePermissionSerializer();
    private vehicleSerializer = new VehicleSerializer();
    private userSerializer = new UserSerializer();

    fromJson(json: any): VehicleDriverInvite {
        return new VehicleDriverInvite(
            this.arrayService.getValue(json, 'id'),
            this.arrayService.getValue(json, 'code'),
            this.arrayService.getValue(json, 'email'),
            this.arrayService.getValue(json, 'first_name'),
            this.arrayService.getValue(json, 'last_name'),
            this.getItems(json),
            this.getVehicle(json),
            this.getOwner(json),
            this.arrayService.getValue(json, 'access_token')
        );
    }

    toJson(resource: VehicleDriverInvite): any {
        return {}
    }

    private getItems(json: any): VehiclePermission | null {
        const dataItemJson = this.arrayService.getValue(json, 'permissions');
        return dataItemJson ? this.vehiclePermissionSerializer.fromJson(dataItemJson) : null;
    }

    private getVehicle(json: any): Vehicle | null {
        const dataItemJson = this.arrayService.getValue(json, 'vehicle');
        return dataItemJson ? this.vehicleSerializer.fromJson(dataItemJson) : null;
    }

    private getOwner(json: any): User | null {
        const dataItemJson = this.arrayService.getValue(json, 'owner');
        return dataItemJson ? this.userSerializer.fromJson(dataItemJson) : null;
    }

}
