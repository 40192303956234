<ng-template #echoModalTemplate let-modal>
    <div class="text-centered">
        <div class="modal-success" id="modal-basic-title">
            <div class="base-form p-t-8 p-b-8">
                <div class="inner">
                    <div class="text-center">
                        <img *ngIf="messageType == 'coin-reward'" src="/assets/img/success.png" alt="Logo" width="350">
                    </div>
                    <h3 class="bold-1 m-b-1 m-t-3 text-center text-white">
                        {{'general.coin-rewared.title' | translate }}
                    </h3>
                    <p class="text-center p3 font-weight-400 line-height-20 text-white">
                        {{modalData?.message ?? 'You have earned additional coins!' }}
                    </p>
                    <div class="m-t-1">
                        <button class="btn fe-btn btn-primary fe-full-width m-t-5" (click)="modal.close()">
                            {{'general.btn-close' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>