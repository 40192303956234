import { Serializer } from './serializer';
import { ArrayService } from '../../services/array.service';
import { VehicleTransferInvite } from '../resource-models/vehicle-transfer-invite';
import { VehicleTransfersOverview } from '../resource-models/vehicle-transfers-overview';

export class VehicleTransfersOverviewSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): VehicleTransfersOverview {
        return new VehicleTransfersOverview(
            this.arrayService.getValue(json, 'id'),
            this.arrayService.getValue(json, 'technicalVisitLogs'),
            this.arrayService.getValue(json, 'odometerLogs'),
            this.arrayService.getValue(json, 'expenseLogs')
        );
    }

    toJson(resource: VehicleTransfersOverview): any {
        return {}
    }

}
