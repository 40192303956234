import { Resource } from "./resource";

export class UserConfig extends Resource {
    key: string;
    value: string;

    constructor(
        key: string,
        value: string,
    ) {
        super();
        this.key = key;
        this.value = value;
    }

}