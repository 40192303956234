import { Resource } from "./resource"

export class Currency extends Resource {

    name: string;
    symbol: string;
    symbolNative: string;
    decimalDigits: string;
    rounding: string;
    code: string;
    namePlural: string;

    constructor(
        name: string,
        symbol: string,
        symbolNative: string,
        decimalDigits: string,
        rounding: string,
        code: string,
        namePlural: string
    ) {
        super();
        this.name = name;
        this.symbol = symbol;
        this.symbolNative = symbolNative;
        this.decimalDigits = decimalDigits;
        this.rounding = rounding;
        this.code = code;
        this.namePlural = namePlural;
    }
}
