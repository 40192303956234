import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { FuelType } from '../resource-models/fuel-type';

export class FuelTypeSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): FuelType {
        return new FuelType(
            this.arrayService.getValue(json, 'key'),
            this.arrayService.getValue(json, 'name')
        );
    }

    toJson(resource: FuelType): any {
        return {
            key: resource.key
        }
    }
}
