import { Resource } from "./resource";

export class VehicleExpenseMeta extends Resource {
    fuelPrice: number;
    fuelQuantity: number;
    avgFuelConsumption: number;

    constructor(
        fuelPrice: number,
        fuelQuantity: number,
        avgFuelConsumption: number
    ) {
        super();
        this.fuelPrice = fuelPrice;
        this.fuelQuantity = fuelQuantity;
        this.avgFuelConsumption = avgFuelConsumption;
    }

}
