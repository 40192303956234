import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/resource-models/user';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { VehicleService } from 'src/app/services/vehicle/vehicle.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-app-nav-content',
    templateUrl: './app-nav-content.component.html',
    styleUrls: ['./app-nav-content.component.scss'],
})
export class AppNavContentComponent implements OnInit, AfterViewInit {
    @Input() isBottomMenu: boolean | undefined;
    @Input() isBottomSideMenu: boolean | undefined;
    @Input() isCollapsed: boolean | null = null;
    showLoader = false;

    navItems: any[] = [];
    navItemsFiltered: any[] = [];
    currentUrl = '';
    user: User | null = null;
    user$: Observable<User | null> = this.userService.storageWebUser$;

    constructor(
        private router: Router,
        private userService: UserService,
        private vehicleService: VehicleService,
        private auth: AuthService
    ) {}

    ngOnInit(): void {
        this.setNavItems();
        this.userService.getUser().subscribe({
            next: (user) => {
                this.userService.setUser(user);
                this.user = user;
                this.buildNavItems();
            },
        });
        this.markActiveIfChildActive();
    }

    ngAfterViewInit(): void {
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.markActiveIfChildActive();
            }
        });
    }

    private setNavItems() {
        this.navItems = [
            {
                icon: 'icon-aot-dashboard',
                label: 'nav.app.dashboard',
                route: '/dashboard',
                childRoutes: [],
                isChildActive: false,
                permissions: [],
                roles: [],
                showOnBottomSideMenu: false,
                showOnSideMenu: true,
                order: 1,
                clickCallback: false,
            },
            {
                icon: 'icon-aot-vozila',
                label: 'nav.app.my-vehicles',
                route: '/dashboard/vehicles',
                childRoutes: [
                    '/vehicle-details',
                    '/vehicle-drivers',
                    '/vehicle-reminders',
                    '/vehicle-odometer',
                ],
                isChildActive: false,
                permissions: [],
                roles: [],
                showOnBottomSideMenu: false,
                showOnSideMenu: true,
                order: 2,
                clickCallback: false,
            },
            {
                icon: 'icon-aot-troskovi',
                label: 'nav.app.expenses',
                route: '/dashboard/expenses/expenses-overview',
                childRoutes: [],
                isChildActive: false,
                permissions: [],
                roles: [],
                showOnBottomSideMenu: false,
                showOnSideMenu: true,
                order: 3,
                clickCallback: false,
            },
            {
                icon: 'icon-travel-case',
                label: 'nav.app.tours',
                route: '/dashboard/tour',
                childRoutes: ['/new-tour', '/tour/details'],
                isChildActive: false,
                permissions: ['index-travel'],
                roles: [],
                showOnBottomSideMenu: false,
                showOnSideMenu: true,
                order: 4,
                clickCallback: false,
            },
            {
                icon: 'icon-book',
                label: 'nav.main.digital-log',
                route: '#',
                childRoutes: ['/vehicle-service-book'],
                isChildActive: false,
                permissions: [],
                roles: [],
                showOnBottomSideMenu: false,
                showOnSideMenu: true,
                order: 5,
                clickCallback: () => this.redirectToServiceBook(),
            },
            {
                icon: 'icon-cog',
                label: 'nav.main.settings',
                route: '/dashboard/settings',
                childRoutes: [],
                isChildActive: false,
                permissions: [],
                roles: [],
                showOnBottomSideMenu: true,
                showOnSideMenu: false,
                order: 6,
                clickCallback: false,
            },
            {
                icon: 'icon-cog',
                label: 'nav.main.report',
                route: '/dashboard/report-bug',
                childRoutes: [],
                isChildActive: false,
                permissions: [],
                roles: [],
                showOnBottomSideMenu: true,
                showOnSideMenu: false,
                order: 7,
                clickCallback: false,
            },
            {
                icon: 'icon-cog',
                label: 'nav.main.privacy-policy',
                route: null,
                childRoutes: [],
                isChildActive: false,
                permissions: [],
                roles: [],
                showOnBottomSideMenu: true,
                showOnSideMenu: false,
                order: 7,
                clickCallback: () => this.openAsExternalLink('/page/privacy-policy'),
            },
            {
                icon: 'icon-cog',
                label: 'nav.main.terms-and-conditions',
                route: null,
                childRoutes: [],
                isChildActive: false,
                permissions: [],
                roles: [],
                showOnBottomSideMenu: true,
                showOnSideMenu: false,
                order: 7,
                clickCallback: () => this.openAsExternalLink('/page/terms-and-conditions'),
            },
        ];
    }

    private buildNavItems() {
        if (this.isBottomSideMenu) {
            this.navItems = this.navItems.filter(
                (x) => x.showOnBottomSideMenu === true
            );
        } else {
            this.navItems = this.navItems.filter(
                (x) => x.showOnSideMenu === true
            );
        }
        this.navItems.forEach((item) => {
            if (!item['permissions'].length) {
                this.navItemsFiltered.push(item);
            } else {
                const userPermissions = this.user?.permissions.map(
                    (x) => x.name
                );
                for (let i = 0; i < item['permissions'].length; i++) {
                    if (userPermissions?.includes(item['permissions'][i])) {
                        this.navItemsFiltered.push(item);
                    }
                }
                const userRoles = this.user?.roles.map((x) => x.name);
                for (let i = 0; i < item['roles'].length; i++) {
                    if (userRoles?.includes(item['roles'][i])) {
                        this.navItemsFiltered.push(item);
                    }
                }
            }
        });
    }

    onNavigationSelection(navItem: any) {
        if (navItem.clickCallback) {
            return navItem.clickCallback();
        }
        this.router.navigate([navItem.route]);
    }

    markActiveIfChildActive(): void {
        this.currentUrl = window.location.pathname;
        this.navItems.forEach((item) => {
            item.isChildActive = false;
            item.childRoutes.forEach((childRoute: any) => {
                if (this.currentUrl.includes(childRoute)) {
                    setTimeout(() => {
                        item.isChildActive = true;
                    });
                }
            });
        });
    }

    private redirectToServiceBook() {
        this.showLoader = true;
        return new Promise<void>((resolve, reject) => {
            this.vehicleService.getVehicles().subscribe({
                next: (vehicles) => {
                    const fav = vehicles.find((x) => x.isFavorite === true);
                    if (fav) {
                        this.showLoader = false;
                        this.router.navigate([
                            '/dashboard/vehicles/vehicle-details/' +
                                fav.id +
                                '/service-book',
                        ]);
                        resolve(); // Resolve the Promise when done
                    }
                },
                error: () => {
                    this.showLoader = false;
                    reject(); // Reject the Promise on error
                },
            });
        });
    }

    private openAsExternalLink(link: string) {
        return new Promise<void>((resolve, reject) => {
            window.open(environment.apiBaseUrl + link, '_blank');
            resolve();
        });
    }

    logout() {
        this.auth.logout();
    }
}
