import {Resource} from '../../models/resource-models/resource';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Serializer} from '../../models/serializers/serializer';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AuthService} from '../auth.service';

export class Delete<T extends Resource> {
    protected url = environment.apiUrl;

    constructor(
        private httpClient: HttpClient,
        private auth: AuthService,
        private responseSerializer: Serializer,
        private endpoint: string | null,
        private parentEndpoint: string,
    ) {
    }

    delete(parentId: string | null, id: any, model?: any, headers?: HttpHeaders): Observable<T> {
        const idUrlPart = id === undefined || id === null ? '' : '/' + id;
        const parentIdPart = parentId === undefined || parentId == null ? '' : '/' + parentId;
        const parentUrlPart = this.parentEndpoint === null ? '' : '/' + this.parentEndpoint;
        const endpointUrlPart = this.endpoint === null ? '' : '/' + this.endpoint;
        const url = this.url + parentUrlPart + parentIdPart + endpointUrlPart + idUrlPart;
        const params = {headers: headers ? headers : {}, body: model};

        return this.httpClient.delete(url, params)
            .pipe(map((data: any) => {
                return this.responseSerializer.fromJson(data) as T;
            }),
            catchError((err, caught) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this.auth.logout();
                }
                throw err;
            }));
    }

    deleteWithParams(parentId: string, id: string, model?: any, params = {}): Observable<T> {
        const idUrlPart = id === undefined || id === null ? '' : '/' + id;
        const parentIdPart = parentId === undefined || parentId == null ? '' : '/' + parentId;
        const parentUrlPart = this.parentEndpoint === null ? '' : '/' + this.parentEndpoint;
        const endpointUrlPart = this.endpoint === null ? '' : '/' + this.endpoint;
        const url = this.url + parentUrlPart + parentIdPart + endpointUrlPart + idUrlPart;

        return this.httpClient.delete(url, params)
            .pipe(map((data: any) => {
                return this.responseSerializer.fromJson(data) as T;
            }),
            catchError((err, caught) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this.auth.logout();
                }
                throw err;
            }));
    }
}
