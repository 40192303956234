import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LangSwitcherService } from '../services/lang-switcher.service';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const locale = LangSwitcherService.getUserSelectedLang();
        if (locale) {
            return next.handle(this.getRequestWithCustomHeaders(request, locale));
        }
        return next.handle(request);
    }

    private getRequestWithCustomHeaders(req: HttpRequest<any>, locale: string) {
        return req.clone({
            headers: req.headers
                .set('X-localization', locale)
        });
    }
}
