import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-echo-modal',
    templateUrl: './echo-modal.component.html',
})
export class EchoModalComponent {
    private modalRef!: NgbModalRef | null;
    @ViewChild('echoModalTemplate', { static: false }) echoModalTemplate: any;
    public modalData: any;
    public messageType: 'coin-reward' = 'coin-reward';

    constructor(private modalService: NgbModal) {}

    openModal(data: any, messageType: 'coin-reward' = 'coin-reward'): void {
        this.modalData = null;
        this.modalData = data;
        this.modalRef = this.modalService.open(this.echoModalTemplate, {
            size: 'lg',
            centered: true,
            windowClass: 'backdrop-blur-modal backdrop-driver-success-modal',
            backdrop: false,
        });

        this.modalRef.result.finally(() => {
            this.modalRef = null;
        });
    }

    closeModal(): void {
        if (this.modalRef) {
            this.modalRef.close();
        }
    }
}
