import { Resource } from "./resource";

export class File extends Resource {
    override id: number;
    path: string;
    bytes: number;
    width: number;
    height: number;
    fileType: string;
    createdAt: string;
    isVideo: boolean;

    constructor(
        id: number,
        path: string,
        bytes: number,
        width: number,
        height: number,
        fileType: string,
        createdAt: string,
        isVideo: boolean,
    ) {
        super();
        this.id = id;
        this.path = path;
        this.bytes = bytes;
        this.width = width;
        this.height = height;
        this.fileType = fileType;
        this.createdAt = createdAt;
        this.isVideo = isVideo;
    }

}
