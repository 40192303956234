<!-- side bar main wrapper -->
<div class="side-nav-bar"
    [ngClass]="{ 'side-nav-bar-collapsed': !(showSideNav | async) }">
    
    <!-- overlay -->
    <div class="side-nav-bar-overlay" 
    [ngStyle]="{ 'transition': 'background-color ' + duration + 's, visibility ' + duration + 's' }"
    [ngClass]="{ 'side-nav-bar-overlay-collapsed': !(showSideNav | async) }"
        (click)="onSidebarClose()"></div>
    
    <!-- side bar-->
    <div class="side-nav-bar-menu-container" 
    [ngStyle]="getSideNavBarStyle((showSideNav | async))">

        <div class="logo">
            <a [routerLink]="'/'">
                <img src="/assets/img/logo-short.svg" alt="Logo" width="60">
            </a>
        </div>
        
        <!-- close button -->
        <div><i class="side-nav-bar-close icon-aot-close" (click)="onSidebarClose()"></i></div>

        <!-- side bar content -->
        <div class="side-nav-bar-content-container">
            <ng-container *ngTemplateOutlet="sidenavTemplateRef"></ng-container>
        </div>
    </div>
</div>