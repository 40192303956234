import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
    selector: 'app-site-layout',
    templateUrl: './site-layout.component.html',
    styleUrls: ['./site-layout.component.scss']
})
export class SiteLayoutComponent implements OnInit {

    isCollapsed = true;
    isLoggedIn = false;

    constructor(
        private navService: NavigationService,
        private auth: AuthService
    ) { }

    ngOnInit(): void {
        this.isLoggedIn = this.auth.isLoggedIn();
    }

    toggleSideNav() {
        this.navService.setShowNav(true);
    }

}
