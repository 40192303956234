import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Serializer} from '../../models/serializers/serializer';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AuthService} from '../auth.service';

export class List<T> {
    protected url = environment.apiUrl;
    public listKey: string | null = 'data';

    constructor(
        private httpClient: HttpClient,
        private auth: AuthService,
        private serializer: Serializer,
        private endpoint: string | null,
        private parentEndpoint: string,
    ) {
    }

    list(parentId: any = null, id: any = null, params: any = {}): Observable<T[]> {
        const idUrlPart = id === undefined || id === null ? '' : '/' + id;
        const parentIdPart = parentId === undefined || parentId == null ? '' : '/' + parentId;
        const parentUrlPart = this.parentEndpoint === null ? '' : '/' + this.parentEndpoint;
        const endpointUrlPart = this.endpoint === null ? '' : '/' + this.endpoint;
        const url = this.url + parentUrlPart + parentIdPart + endpointUrlPart + idUrlPart;
        
        return this.httpClient
            .get(url, {params: params})
            .pipe(map((data: any) => {
                if (this.listKey != null && data) {
                    return this.convertData(data[this.listKey]);
                } else if (typeof (data.data) === 'undefined') {
                    return this.convertData(data);
                }
                return this.convertData(data.data);
            }),
            catchError((err, caught) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this.auth.logout();
                }
                throw err;
            }));
    }

    listWithOptions(parentId = null, id = null, options: any): Observable<T[]> {
        const idUrlPart = id === undefined || id === null ? '' : '/' + id;
        const parentIdPart = parentId === undefined || parentId == null ? '' : '/' + parentId;
        const parentUrlPart = this.parentEndpoint === null ? '' : '/' + this.parentEndpoint;
        const endpointUrlPart = this.endpoint === null ? '' : '/' + this.endpoint;
        const url = this.url + parentUrlPart + parentIdPart + endpointUrlPart + idUrlPart;
        return this.httpClient
            .get(url, options)
            .pipe(map((data: any) => {
                if (this.listKey != null && data) {
                    return this.convertData(data[this.listKey]);
                } else if (typeof (data.data) === 'undefined') {
                    return this.convertData(data);
                }
                return this.convertData(data.data);
            }),
            catchError((err, caught) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this.auth.logout();
                }
                throw err;
            }));
    }

    private convertData(data: any): T[] {
        return data.map((item: any) => this.serializer.fromJson(item));
    }
}
