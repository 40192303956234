import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { VehicleExpense } from '../resource-models/vehicle-expense';
import { VehicleExpensePaginate } from '../resource-models/vehicle-expense-paginate';
import { VehicleExpenseSerializer } from './vehicle-expense-serializer';
import { Paginator } from '../resource-models/paginator';
import { PaginatorSerializer } from './paginator-serializer';

export class VehicleExpensePaginateSerializer implements Serializer {
    private arrayService = new ArrayService();
    private expenseSerializer = new VehicleExpenseSerializer();
    private paginatorSerializer = new PaginatorSerializer();

    fromJson(json: any): VehicleExpensePaginate {
        return new VehicleExpensePaginate(
            this.getExpenses(json),
            this.getPaginateData(json)
        );
    }

    toJson(resource: VehicleExpensePaginate): any {
        return {}
    }

    private getExpenses(json: any): VehicleExpense[] | [] {
        const dataItemsArray = this.arrayService.getValue(json, 'data');
        return dataItemsArray === null ? [] : dataItemsArray.map((dataItemJson: any) => {
            return this.expenseSerializer.fromJson(dataItemJson);
        });
    }

    private getPaginateData(json: any): Paginator | null {
        const dataItemJson = this.arrayService.getValue(json, 'meta');
        return dataItemJson ? this.paginatorSerializer.fromJson(dataItemJson) : null;
    }

}
