import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { Paginator } from '../resource-models/paginator';

export class PaginatorSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): Paginator {
        return new Paginator(
            this.arrayService.getValue(json, 'current_page'),
            this.arrayService.getValue(json, 'from'),
            this.arrayService.getValue(json, 'last_page'),
            this.arrayService.getValue(json, 'per_page'),
            this.arrayService.getValue(json, 'to'),
            this.arrayService.getValue(json, 'total'),
        );
    }

    toJson(resource: Paginator): any {
        return {
        }
    }
}
