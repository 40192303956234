import { Resource } from "./resource";

export class VehicleBrand extends Resource {
    override id: number;
    title: string;
    logo: string;


    constructor(
        id: number,
        title: string,
        logo: string
    ) {
        super();
        this.id = id;
        this.title = title;
        this.logo = logo;
    }

}