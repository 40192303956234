import { Serializer } from './serializer';
import { ArrayService } from '../../services/array.service';
import { VehicleModel } from '../resource-models/vehicle-model';
import { VehicleBrand } from '../resource-models/vehicle-brand';
import { VehicleBrandSerializer } from './vehicle-brand-serializer';
import { Vehicle } from '../resource-models/vehicle';
import { VehiclePermissionSerializer } from './vehicle-permission-serializer';
import { VehiclePermission } from '../resource-models/vehicle-permission';

export class VehicleSerializer implements Serializer {
    private arrayService = new ArrayService();
    private vehicleBrandSerializer = new VehicleBrandSerializer();
    private vehiclePermissionSerializer = new VehiclePermissionSerializer();

    fromJson(json: any): Vehicle {
        return new Vehicle(
            this.arrayService.getValue(json, 'id'),
            this.arrayService.getValue(json, 'name'),
            this.getBrand(json),
            this.getModel(json),
            this.arrayService.getValue(json, 'year'),
            this.arrayService.getValue(json, 'power'),
            this.arrayService.getValue(json, 'vin'),
            this.arrayService.getValue(json, 'fuel'),
            this.arrayService.getValue(json, 'plates'),
            this.arrayService.getValue(json, 'lastRegistered'),
            this.arrayService.getValue(json, 'favorite', false),
            this.arrayService.getValue(json, 'odometer'),
            this.arrayService.getValue(json, 'odometerLastUpdateDate'),
            this.arrayService.getValue(json, 'isOwner'),
            this.arrayService.getValue(json, 'createdAt'),
            this.arrayService.getValue(json, 'fuelSlug'),
            this.arrayService.getValue(json, 'driversCode'),
            this.getPermissions(json, 'driversDefaultPermissions'),
            this.getPermissions(json, 'permissions'),
            this.arrayService.getValue(json, 'type'),
            this.arrayService.getValue(json, 'typeSlug'),
            this.arrayService.getValue(json, 'maxNumberOfDrivers')
        );
    }

    toJson(resource: Vehicle): any {
        return {}
    }

    private getBrand(json: any): VehicleBrand | null {
        const dataItemJson = this.arrayService.getValue(json, 'brand');
        return dataItemJson ? this.vehicleBrandSerializer.fromJson(dataItemJson) : null;
    }

    private getModel(json: any): VehicleModel | null {
        const dataItemJson = this.arrayService.getValue(json, 'model');
        return dataItemJson ? this.vehicleBrandSerializer.fromJson(dataItemJson) : null;
    }

    private getPermissions(json: any, context: string): VehiclePermission | null {
        const dataItemJson = this.arrayService.getValue(json, context);
        return dataItemJson ? this.vehiclePermissionSerializer.fromJson(dataItemJson) : null;
    }

}
