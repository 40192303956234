export const environment = {
    production: false,
    baseUrl: "https://app.development.autooptimo.com",
    apiUrl: "https://development.autooptimo.com/api",
    apiBaseUrl: "https://development.autooptimo.com",
    loginWebVersion: "v1",
    userVersion: "v1",
    vehicleVersion: "v1",
    countryVersion: "v1",
    salesMail: "sales@autooptimo.com",
    imageVersion: "v1",
    gloveBoxVersion: "v1",
    useLocalTranslations: false,
    appVersion: '1.0.8',
    showAppVersion: true,
    googleAnalyticsId: undefined,
    appStoreLink: 'https://apps.apple.com/us/app/autooptimo-car-management/id6449407957',
    playStoreLink: 'https://play.google.com/store/apps/details?id=com.autooptimo.autooptimo_mobile',
    monriBaseUrl: 'https://ipgtest.monri.com',
    monriClientSecret: 'key-741bcc2296e70051f148e57cfbad29c7',
    useMonri: true,
    reverbKey: 'rpt5kfggdudah4sfiltb',
    reverbHost: 'ws.development.autooptimo.com',
    reverbPort: 443,
    reverbScheme: 'https',  
};