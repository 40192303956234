import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NavigationService } from '../services/navigation.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class MobileDeepLinkInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const url = new URL(window.location.href);
        const pathName = url.pathname;
        const deepLink = NavigationService.deepLinkPairs.find(
            (link) => link.mobile === pathName
        );
        if (deepLink) {
            window.location.href = environment.baseUrl + deepLink.web;
        }
        return next.handle(request);
    }
}
