import { Serializer } from './serializer';
import { ArrayService } from '../../services/array.service';
import { ExpenseType } from '../resource-models/expense-type';

export class ExpenseTypeSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): ExpenseType {
        return new ExpenseType(
            this.arrayService.getValue(json, 'id'),
            this.arrayService.getValue(json, 'name'),
            this.arrayService.getValue(json, 'slug'),
            this.arrayService.getValue(json, 'icon'),
            this.arrayService.getValue(json, 'order'),
            this.arrayService.getValue(json, 'color')
        );
    }

    toJson(resource: ExpenseType): any {
        return {}
    }
}
