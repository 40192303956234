import { ExpenseType } from "./expense-type";
import { File } from "./file";
import { Resource } from "./resource";
import { Vehicle } from "./vehicle";
import {VehicleExpenseMeta} from "./vehicle-expense-meta";
import {Currency} from "./currency";

export class VehicleExpense extends Resource {
    override id: number;
    price: string;
    comment: string;
    dateOfWork: string;
    expenseType: ExpenseType | null;
    images: File[] | [];
    vehicle: Vehicle | null;
    meta: VehicleExpenseMeta | undefined;
    odometer: number | null;
    currency: Currency | null;

    constructor(
        id: number,
        price: string,
        comment: string,
        dateOfWork: string,
        expenseType: ExpenseType | null,
        images: File[] | [],
        vehicle: Vehicle | null,
        meta: VehicleExpenseMeta | undefined,
        odometer: number | null,
        currency: Currency | null,
    ) {
        super();
        this.id = id;
        this.price = price;
        this.comment = comment;
        this.dateOfWork = dateOfWork;
        this.expenseType = expenseType;
        this.images = images;
        this.vehicle = vehicle;
        this.meta = meta;
        this.odometer = odometer;
        this.currency = currency;
    }

}
