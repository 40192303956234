import { Serializer } from './serializer';
import { ArrayService } from '../../services/array.service';
import { Role } from '../resource-models/role';
import { RoleSerializer } from './role-serializer';
import { User } from '../resource-models/user';
import { PermissionSerializer } from './permission-serializer';
import { UserSetting } from '../resource-models/user-setting';
import { UserSettingSerializer } from './user-setting-serializer';
import { UserConfig } from '../resource-models/user-config';
import { UserConfigSerializer } from './user-config-serializer';
import { CountrySerializer } from './country-serializer';
import { Country } from '../resource-models/country';

export class UserSerializer implements Serializer {
    private arrayService = new ArrayService();
    private roleSerializer = new RoleSerializer();
    private peremissionSerializer = new PermissionSerializer();
    private userSettingsSerializer = new UserSettingSerializer();
    private userConfigSerializer = new UserConfigSerializer();
    private countrySerializer = new CountrySerializer();

    fromJson(json: any): User {
        return new User(
            this.arrayService.getValue(json, 'id'),
            this.arrayService.getValue(json, 'avatar'),
            this.arrayService.getValue(json, 'email'),
            this.arrayService.getValue(json, 'firstName'),
            this.arrayService.getValue(json, 'lastName'),
            this.getItems(json, 'roles'),
            this.getItems(json, 'permissions'),
            this.getSettings(json),
            this.getConfig(json),
            this.getCountry(json),
            this.arrayService.getValue(json, 'emailVerified'),
            this.arrayService.getValue(json, 'coin_balance'),
            this.arrayService.getValue(json, 'coin_usage'),
            this.arrayService.getValue(json, 'authType'),
        );
    }

    toJson(resource: Role): any {
    }

    private getItems(json: any, itemName: string): Role[] | [] {
        const dataItemsArray = this.arrayService.getValue(json, itemName);
        return dataItemsArray === null ? [] : dataItemsArray.map((dataItemJson: any) => {
            if (itemName === 'roles') {
                return this.roleSerializer.fromJson(dataItemJson);
            }
            return this.peremissionSerializer.fromJson(dataItemJson);
        });
    }

    private getSettings(json: any): UserSetting[] | [] {
        const dataItemsArray = this.arrayService.getValue(json, 'settings');
        return dataItemsArray === null ? [] : dataItemsArray.map((dataItemJson: any) => {
            return this.userSettingsSerializer.fromJson(dataItemJson);
        });
    }


    private getConfig(json: any): UserConfig[] | [] {
        const dataItemsArray = this.arrayService.getValue(json, 'config');
        return dataItemsArray === null ? [] : dataItemsArray.map((dataItemJson: any) => {
            return this.userConfigSerializer.fromJson(dataItemJson);
        });
    }

    private getCountry(json: any): Country | null {
        const dataItem = this.arrayService.getValue(json, 'country');
        return !dataItem ? null : this.countrySerializer.fromJson(dataItem);
    }

}
