import { Resource } from "./resource";
import { TechnicalVisit } from "./technical-visit";
import { VehicleExpense } from "./vehicle-expense";

export class VehicleOdometerEntry extends Resource {

    override id: number;
    value: string;
    date: string;
    expense?: VehicleExpense | null | undefined;
    technicalVisit?: TechnicalVisit | null | undefined;
    notes?: string;

    constructor(
        id: number,
        value: string,
        date: string,
        expense?: VehicleExpense | null | undefined,
        technicalVisit?: TechnicalVisit | null | undefined,
        notes?: string
    ) {
        super();
        this.id = id;
        this.value = value;
        this.date = date;
        this.expense = expense;
        this.technicalVisit = technicalVisit;
        this.notes = notes;
    }

}