import { Resource } from "./resource"

export class Country extends Resource {

    override id: number;
    name: string;
    native: string;
    code: string;

    constructor(
        id: number,
        name: string,
        native: string,
        code: string
    ) {
        super();
        this.id = id;
        this.name = name;
        this.native = native;
        this.code = code;
    }
}