import { Resource } from "./resource";

export class VehicleInviteCode extends Resource {
    code: string;

    constructor(
        code: string,
    ) {
        super();
        this.code = code;
    }

}