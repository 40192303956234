import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BearerToken } from '../models/resource-models/bearer-token';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { StorageHelper } from '../helpers/storage-helper';
import { EchoSubscriptionService } from './echo-subscription.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    options: any;
    http: any | undefined;

    constructor(
        private inj: Injector,
        private httpClient: HttpClient,
        private router: Router,
        private deviceDetector: DeviceDetectorService,
        private echoSubscriptionService: EchoSubscriptionService
    ) {
        this.options = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            })
        };
        this.http = this.inj.get(HttpClient);
    }

    public isLoggedIn() {
        return this.getAuthKey() != null;
    }

    public getAuthKey(): string | null {
        const token = localStorage.getItem('bearer_token');
        if (!token) {
            return null;
        }
        const bearerToken = JSON.parse(token || '') as BearerToken;
        return bearerToken.access_token;
    }

    public setBearerToken(auth: BearerToken) {
        localStorage.setItem('bearer_token', JSON.stringify(auth));
    }

    public setCsrfToken(): Observable<any> {
        return this.http.get(environment.apiBaseUrl + '/sanctum/csrf-cookie');
    }

    public login(requestData: { username: string, password: string }): Promise<any> {
        const deviceInfo: DeviceInfo = this.deviceDetector.getDeviceInfo();
        const data = {
            email: requestData.username,
            password: requestData.password,
            device_name: deviceInfo.browser + '_' + deviceInfo.deviceType
        }
        const http = this.inj.get(HttpClient);
        return new Promise((resolve, reject) => {
            http.post<BearerToken>(environment.apiUrl + '/' + environment.loginWebVersion + '/auth', data,
                { headers: this.options.headers, withCredentials: true }).subscribe(
                    {
                        next: (data) => {
                            if (data) {
                                this.setBearerToken(data);
                                resolve(true);
                            }
                            resolve(false);
                        },
                        error: (error) => {
                            reject(error);
                        }
                    }
                );
        });
    }

    public socialLogin(requestData: { authToken: string, userId: string }, service: 'facebook' | 'google' = 'facebook'): Promise<any> {
        const data = {
            authToken: requestData.authToken,
            userId: requestData.userId
        }
        const http = this.inj.get(HttpClient);
        return new Promise((resolve, reject) => {
            http.post<BearerToken>(environment.apiUrl + '/' + environment.userVersion + '/login/' + service, data, { headers: this.options.headers }).subscribe(
                {
                    next: (data) => {
                        if (data) {
                            this.setBearerToken(data);
                            resolve(true);
                        }
                        resolve(false);
                    },
                    error: (error) => {
                        reject(error);
                    }
                }
            );
        });
    }

    public logout(redirectAfterLogout = true) {
        const accessToken = this.getAuthKey();
        if (!accessToken) {
            return;
        }
        this.echoSubscriptionService.unsubscribeFromUserChannels();
        this.httpClient
            .get(environment.apiUrl + '/token/revoke', this.options)
            .subscribe();
        sessionStorage.clear();
        localStorage.removeItem('bearer_token');
        if (redirectAfterLogout) {
            this.router.navigate(['/']);
        }
    }

}
