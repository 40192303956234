import { Currency } from "./currency";
import { Resource } from "./resource";

export class VehicleHistoryEntry extends Resource {

    override id: number;
    entryType: string;
    title: string;
    comment: string | null;
    date: string;
    price: number | null;
    odometer: number | null;
    category: string;
    categoryColor: string;
    currency: Currency | null;
    typeTranslated: string;
    timeAgo: string;

    constructor(
        id: number,
        entryType: string,
        title: string,
        comment: string | null,
        date: string,
        price: number | null,
        odometer: number | null,
        category: string,
        categoryColor: string,
        currency: Currency | null,
        typeTranslated: string,
        timeAgo: string
    ) {
        super();
        this.id = id;
        this.entryType = entryType;
        this.title = title;
        this.comment = comment;
        this.date = date;
        this.price = price;
        this.odometer = odometer;
        this.category = category;
        this.categoryColor = categoryColor;
        this.currency = currency;
        this.typeTranslated = typeTranslated;
        this.timeAgo = timeAgo;
    }

}