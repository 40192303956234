import { VehicleInviteSignUpRequest } from '../resource-models/vehicle-invite-sign-up-request';
import { Serializer } from './serializer';

export class VehicleInviteSignUpRequestSerializer implements Serializer {

    fromJson(json: any): any {
        return {}
    }

    toJson(resource: VehicleInviteSignUpRequest): any {
        return {
            password: resource.password,
            password_confirmation: resource.password_confirmation
        }
    }
}

