import { ChartExpenseData } from "./chart-expense-data";
import { Resource } from "./resource"

export class ChartOdometerData extends Resource {

    name: string;
    series: ChartExpenseData[];
    normalizedSeries?: ChartExpenseData[];

    constructor(
        name: string,
        series: ChartExpenseData[],
        normalizedSeries?: ChartExpenseData[]
    ) {
        super();
        this.name = name;
        this.series = series;
        this.normalizedSeries = normalizedSeries;
    }
}