<router-outlet (activate)="onActivate($event)"></router-outlet>

<div class="row" *ngIf="!isPublicRoute">
    <div class="col-sm-12">
        <div class="mobile-web-warning-section d-md-none" [ngClass]="{ hidden: isHidden }">
            <span class="pull-right mobile-web-warning-close" (click)="dismiss()" style="cursor: pointer">
                <img src="/assets/img/icons/close_btn.svg" width="15" />
            </span>
            <div class="mobile-web-warning">
                <div class="mobile-web-warning-header">
                    <h4 class="bold-1 text-center">
                        {{ "dashboard.mobile-web-warning.title" | translate }}
                    </h4>
                    <p class="p3 line-height-24 font-weight-400 p-color-8 text-center m-t-2 m-b-5">
                        {{
                        "dashboard.mobile-web-warning.subtitle" | translate
                        }}
                    </p>
                </div>
                <div class="">
                    <div class="d-flex justify-content-center mobile-buttons-section">
                        <a href="{{ appStoreLink }}" class="download-btn m-b-1" target="_blank">
                            <img src="/assets/img/social/download-app-store.svg" alt="" width="33" />
                            <p [innerHTML]="
                                    'dashboard.mobile-web-warning.app-store'
                                        | translate
                                "></p>
                        </a>
                        <a href="{{ playStoreLink }}" class="download-btn" target="_blank">
                            <img src="/assets/img/social/download-google-play.svg" alt="" width="33" />
                            <p [innerHTML]="
                                    'dashboard.mobile-web-warning.google-play'
                                        | translate
                                "></p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-echo-modal #echoModal></app-echo-modal>