import { Resource } from "./resource";

export class VehiclePermissionItem extends Resource {

    createResource: boolean;
    readResource: boolean;
    updateResource: boolean;
    deleteResource: boolean;

    constructor(
        createResource: boolean,
        readResource: boolean,
        updateResource: boolean,
        deleteResource: boolean
    ) {
        super();
        this.createResource = createResource;
        this.readResource = readResource;
        this.updateResource = updateResource;
        this.deleteResource = deleteResource;
    }

}