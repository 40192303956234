import {Serializer} from './serializer';
import { GenericResponse } from '../resource-models/generic-response';
import { ArrayService } from 'src/app/services/array.service';

export class GenericResponseSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): GenericResponse {
        return new GenericResponse(
            this.arrayService.getValue(json, 'message')
        );
    }
    toJson(resource: GenericResponse): any {
        return {};
    }

}
