import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, of, Observable, shareReplay } from 'rxjs';
import { PageCta } from '../interfaces/page-cta';
import { PageBreadCrumb } from '../interfaces/page-bread-crumb';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    public pageTitleSubject = new BehaviorSubject<string | null>(null);
    public pageTitle$: Observable<string | null> = this.pageTitleSubject.asObservable();

    public pageCtasSubject = new BehaviorSubject<PageCta[]>([]);
    public pageCtas$: Observable<PageCta[]> = this.pageCtasSubject.asObservable().pipe(shareReplay(1));

    public pageBreadCrumbSubject = new BehaviorSubject<PageBreadCrumb | null>(null);
    public pageBreadCrumb$: Observable<PageBreadCrumb | null> = this.pageBreadCrumbSubject.asObservable().pipe(shareReplay(1));

    public focusedLayoutBackBtnSubject = new BehaviorSubject<boolean>(false);
    public focusedLayoutBackBtn$: Observable<boolean> = this.focusedLayoutBackBtnSubject.asObservable();

    private showNav$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    public collapsedNavStateSubject = new BehaviorSubject<boolean>(false);
    public collapsedNavState$ = this.collapsedNavStateSubject.asObservable();
    public isCollapsedNavStateForced = false;

    public ctaEmitter: EventEmitter<PageCta> = new EventEmitter();
    public readonly mdScreenMinWidth = 991;
    public readonly mdScreenMaxWidth = 1300;
    public readonly xlScreenMinWidth = 1800;

    public static readonly deepLinkPairs: [{mobile: string, web: string}] = [
        {
            mobile: '/mobile/garage',
            web: '/dashboard/vehicles'
        }
    ];

    constructor(private router: Router) {
        router.events.subscribe(() => {
            this.setShowNav(false);
        });
    }

    ngOnInit() { }

    getShowNav() {
        return this.showNav$.asObservable();
    }

    setShowNav(showHide: boolean) {
        this.showNav$.next(showHide);
    }

    toggleNavState() {
        this.showNav$.next(!this.showNav$.value);
    }

    isNavOpen() {
        return this.showNav$.value;
    }

    public setPageTitle(title: string | null): void {
        setTimeout(() => {
            this.pageTitleSubject.next(title);
        }, 0);
    }

    public setPageCtas(ctas: PageCta[]): void {
        this.pageCtasSubject.next(ctas);
    }

    public setPageBreadCrumb(bc: PageBreadCrumb | null): void {
        this.pageBreadCrumbSubject.next(bc);
    }

    public setFocusedLayoutBackBtn(visible: boolean): void {
        this.focusedLayoutBackBtnSubject.next(visible);
    }

    public triggerCtaEmitter(cta: PageCta) {
        this.ctaEmitter.emit(cta);
    }

    public setCollapsedNavState(val: boolean): void {
        this.collapsedNavStateSubject.next(val);
    }

    public forceSetCollapsedNavState(val: boolean) {
        this.isCollapsedNavStateForced = val;
        if (window.innerWidth >= this.xlScreenMinWidth) {
            return;
        }
        this.setCollapsedNavState(val);
    }

}
