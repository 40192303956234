import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpClient,
} from '@angular/common/http';
import { Observable, catchError, switchMap } from 'rxjs';
import { BearerToken } from '../models/resource-models/bearer-token';
import { environment } from 'src/environments/environment';

@Injectable()
export class OneTimeTokenInterceptor implements HttpInterceptor {
    private isInterceptionAllowed = true;

    constructor(private httpClient: HttpClient) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const url = window.location.href;
        const searchParams = new URL(url).searchParams;
        const oneTimeToken = searchParams.get('one-time-token');
        if (this.isInterceptionAllowed && oneTimeToken) {
            // Prevent further interception
            this.isInterceptionAllowed = false;
            return this.httpClient
                .get(
                    environment.apiUrl +
                        '/v1/one-time-token?token=' +
                        oneTimeToken
                )
                .pipe(
                    switchMap((data: any) => {
                        // if a user logged in, logged them out
                        sessionStorage.clear();
                        localStorage.removeItem('bearer_token');
                        localStorage.setItem(
                            'bearer_token',
                            JSON.stringify(new BearerToken(data.access_token))
                        );
                        return next.handle(request);
                    }),
                    catchError((error) => {
                        return next.handle(request);
                    })
                );
        }
        return next.handle(request);
    }
}
