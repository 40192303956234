import { Injectable, Injector } from '@angular/core';
import { EchoService } from './echo.service';
import { ToastrService } from 'ngx-toastr';
import { User } from '../models/resource-models/user';
import { UserService } from './user/user.service';
import { CoinAwardInterface } from './interfaces/coin-award-interface';
import { AuthService } from './auth.service';
import { EchoModalComponent } from '../pages/echo-modal/echo-modal.component';

@Injectable({
    providedIn: 'root',
})
export class EchoSubscriptionService {
    private isSubscribed = false;
    private userService: UserService | undefined;
    private authService: AuthService | undefined;
    private echoModal: EchoModalComponent | undefined;

    constructor(
        private echoService: EchoService,
        private toastr: ToastrService,
        private injector: Injector
    ) {}

    setEchoModal(echoModal: EchoModalComponent): void {
        this.echoModal = echoModal;
    }

    /**
     * Initialize Echo subscriptions based on user state.
     */
    public initialize(): void {
        if (this.isSubscribed) {
            return;
        }
        this.authService = this.injector.get(AuthService);
        if (!this.authService.getAuthKey()) {
            return;
        }
        this.userService = this.injector.get(UserService);
        setTimeout(() => {
            this.userService?.getUser().subscribe((user: User) => {
                if (user && user.id && !this.isSubscribed) {
                    this.subscribeToUserChannels(user.id);
                }
            });
        }, 1000);
    }

    /**
     * Subscribe to private channels for a specific user.
     * @param userId User's ID
     */
    private subscribeToUserChannels(userId: number): void {
        this.isSubscribed = true;
        // Example private channel subscription
        this.echoService.private(
            `user.${userId}`,
            '.coins.awarded',
            (data: CoinAwardInterface) => {
                console.log(data);
                // this.toastr.success(data.message);
                this.echoModal?.openModal({
                    message: data.message,
                    user: data.user,
                });
                this.userService?.setUser(data.user);
            }
        );
        console.log(`Subscribed to Echo channels for user ${userId}`);
    }

    /**
     * Unsubscribe from all active channels.
     */
    public unsubscribeFromUserChannels(): void {
        const userId = this.userService?.getUserInstance()?.id;
        if (!userId) {
            return;
        }
        this.echoService.leave(`user.${userId}`);
        this.isSubscribed = false;
        console.log('Unsubscribed from Echo channels');
    }
}
