import { Resource } from "./resource";
import { VehiclePermissionItem } from "./vehicle-permission-item";

export class VehiclePermission extends Resource {

    expense: VehiclePermissionItem | null;
    vehicle: VehiclePermissionItem | null;
    technicalVisit: VehiclePermissionItem | null;
    odometer: VehiclePermissionItem | null;
    gloveBox: VehiclePermissionItem | null;

    constructor(
        expense: VehiclePermissionItem | null,
        vehicle: VehiclePermissionItem | null,
        technicalVisit: VehiclePermissionItem | null,
        odometer: VehiclePermissionItem | null,
        gloveBox: VehiclePermissionItem | null
    ) {
        super();
        this.expense = expense;
        this.vehicle = vehicle;
        this.technicalVisit = technicalVisit;
        this.odometer = odometer;
        this.gloveBox = gloveBox;
    }

}