import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { VehiclePermissionItem } from '../resource-models/vehicle-permission-item';

export class VehiclePermissionItemSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): VehiclePermissionItem {
        return new VehiclePermissionItem(
            this.arrayService.getValue(json, 'create'),
            this.arrayService.getValue(json, 'read'),
            this.arrayService.getValue(json, 'update'),
            this.arrayService.getValue(json, 'delete')
        );
    }

    toJson(resource: VehiclePermissionItem): any {
        return {}
    }
    
}
