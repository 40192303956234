import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Serializer } from '../../models/serializers/serializer';
import { Resource } from '../../models/resource-models/resource';
import { AuthService } from '../auth.service';

export class Read<T extends Resource> {
    protected url = environment.apiUrl;
    public listKey: string | null = 'data';

    constructor(
        private httpClient: HttpClient,
        private auth: AuthService,
        private serializer: Serializer,
        private endpoint: string | null,
        private parentEndpoint: string | null,
    ) {
    }

    read(parentId: string | number | null, id: string | number | null, params: any = {}, headers: HttpHeaders | null): Observable<T> {
        const idUrlPart = id === undefined || id === null ? '' : '/' + id;
        const parentIdPart = parentId === undefined || parentId == null ? '' : '/' + parentId;
        const parentUrlPart = this.parentEndpoint === null ? '' : '/' + this.parentEndpoint;
        const endpointUrlPart = this.endpoint === null ? '' : '/' + this.endpoint;
        const url = this.url + parentUrlPart + parentIdPart + endpointUrlPart + idUrlPart;
        let options = { params: params };
        if (headers) {
            options = Object.assign({ headers: headers }, options);
        }
        return this.httpClient
            .get(url, options)
            .pipe(map((data: any) => {
                if (this.listKey != null && data) {
                    return this.serializer.fromJson(data[this.listKey]) as T;
                }
                return this.serializer.fromJson(data) as T;
            }),
            catchError((err, caught) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this.auth.logout();
                }
                throw err;
                return [];
            }));
    }


    readWithOptions(parentId = null, id = null, options: any): Observable<T> | Observable<T[]> {
        const idUrlPart = id === undefined || id === null ? '' : '/' + id;
        const parentIdPart = parentId === undefined || parentId == null ? '' : '/' + parentId;
        const parentUrlPart = this.parentEndpoint === null ? '' : '/' + this.parentEndpoint;
        const endpointUrlPart = this.endpoint === null ? '' : '/' + this.endpoint;
        const url = this.url + parentUrlPart + parentIdPart + endpointUrlPart + idUrlPart;
        return this.httpClient
            .get(url, options)
            .pipe(map((data: any) => {
                if (this.listKey != null && data) {
                    return this.serializer.fromJson(data[this.listKey]) as T;
                }
                return this.serializer.fromJson(data) as T;
            }),
            catchError((err, caught) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this.auth.logout();
                }
                throw err;
            }));
    }

    readHttpResponse(parentId: string, id: string, params: any = {}, headers: HttpHeaders | null): Observable<T> {
        const idUrlPart = id === undefined || id === null ? '' : '/' + id;
        const parentIdPart = parentId === undefined || parentId == null ? '' : '/' + parentId;
        const parentUrlPart = this.parentEndpoint === null ? '' : '/' + this.parentEndpoint;
        const endpointUrlPart = this.endpoint === null ? '' : '/' + this.endpoint;
        const url = this.url + parentUrlPart + parentIdPart + endpointUrlPart + idUrlPart;
        let options = { params: params };
        if (headers) {
            options = Object.assign({ headers: headers }, options);
        }
        options = Object.assign({ observe: 'response' }, options);

        return this.httpClient
            .get<any>(url, options)
            .pipe(map((data: HttpResponse<any>) => {
                return this.serializer.fromJson(data) as T;
            }),
            catchError((err, caught) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this.auth.logout();
                }
                throw err;
            }));
    }

}
