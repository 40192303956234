import { Serializer } from './serializer';
import { ArrayService } from '../../services/array.service';
import { VehicleReminderCategory } from '../resource-models/vehicle-reminder-category';
import { VehicleOdometerEntry } from '../resource-models/vehicle-odometer-entry';
import { VehicleExpense } from '../resource-models/vehicle-expense';
import { TechnicalVisit } from '../resource-models/technical-visit';
import { VehicleExpenseSerializer } from './vehicle-expense-serializer';
import { TechnicalVisitSerializer } from './technical-visit-serializer';

export class VehicleOdometerEntrySerializer implements Serializer {
    private arrayService = new ArrayService();
    private vehicleExpenseSerializer = new VehicleExpenseSerializer();
    private technicalVisitSerializer = new TechnicalVisitSerializer();

    fromJson(json: any): VehicleOdometerEntry {
        return new VehicleOdometerEntry(
            this.arrayService.getValue(json, 'id'),
            this.arrayService.getValue(json, 'value'),
            this.arrayService.getValue(json, 'date'),
            this.getExpenseModel(json),
            this.getTechnicalVisitModel(json),
            this.arrayService.getValue(json, 'notes'),
        );
    }

    toJson(resource: VehicleOdometerEntry): any {
        return {
            "notes": resource.notes
        }
    }

    private getExpenseModel(json: any): VehicleExpense | null {
        const item = this.arrayService.getValue(json, 'expense')
        return item ? this.vehicleExpenseSerializer.fromJson(item) : null;
    }

    private getTechnicalVisitModel(json: any): TechnicalVisit | null {
        const item = this.arrayService.getValue(json, 'technicalVisit')
        return item ? this.technicalVisitSerializer.fromJson(item) : null;
    }

}
