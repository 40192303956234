import {Resource} from '../../models/resource-models/resource';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Serializer} from '../../models/serializers/serializer';
import {AuthService} from '../auth.service';

export class Create<T1 extends Resource | FormData, T2 extends Resource> {
    public url = environment.apiUrl;
    public listKey: string | null = 'data';

    constructor(
        private httpClient: HttpClient,
        private auth: AuthService,
        private requestSerializer: Serializer,
        private responseSerializer: Serializer,
        private endpoint: string | null,
        private parentEndpoint: string | null
    ) {
    }

    public create(parentId: any, id: any, item: T1): Observable<T2> {
        const idUrlPart = id === undefined || id === null ? '' : '/' + id;
        const parentIdPart = parentId === undefined || parentId == null ? '' : '/' + parentId;
        const parentUrlPart = this.parentEndpoint === null ? '' : '/' + this.parentEndpoint;
        const endpointUrlPart = this.endpoint === null ? '' : '/' + this.endpoint;
        const url = this.url + parentUrlPart + parentIdPart + endpointUrlPart + idUrlPart;
        return this.httpClient
            .post<T2>(url, item instanceof Resource ? this.requestSerializer.toJson(item) : item)
            .pipe(map((data: any) => {
                if (this.listKey != null && data) {
                    return this.responseSerializer.fromJson(data[this.listKey]) as T2;
                }
                return this.responseSerializer.fromJson(data) as T2;
            }),
            catchError((err, caught) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this.auth.logout();
                }
                throw err;
            }));
    }

    public createWithOptions(parentId: number | null, id: number | string | null, item: T1, options: any): Observable<T2> {
        const idUrlPart = id === undefined || id === null ? '' : '/' + id;
        const parentIdPart = parentId === undefined || parentId == null ? '' : '/' + parentId;
        const parentUrlPart = this.parentEndpoint === null ? '' : '/' + this.parentEndpoint;
        const endpointUrlPart = this.endpoint === null ? '' : '/' + this.endpoint;
        const url = this.url + parentUrlPart + parentIdPart + endpointUrlPart + idUrlPart;

        return this.httpClient
            .post<T2>(url, item instanceof Resource ? this.requestSerializer.toJson(item) : item, options)
            .pipe(map((data: any) => {
                if (this.listKey != null && data) {
                    return this.responseSerializer.fromJson(data[this.listKey]) as T2;
                }
                return this.responseSerializer.fromJson(data) as T2;
            }),
            catchError((err, caught) => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this.auth.logout();
                }
                throw err;
            }));
    }
}
