import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpXsrfTokenExtractor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class BearerInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        private tokenExtractor: HttpXsrfTokenExtractor
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        if (request.url.includes('/auth')) {
            return next.handle(request.clone());
        }

        const headerName = 'X-XSRF-TOKEN';
        let token = this.tokenExtractor.getToken() as string;

        if (token !== null && !request.headers.has(headerName)) {
            return next.handle(request.clone({ headers: request.headers.set(headerName, token) }));
        }

        const authKey = this.authService.getAuthKey();
        if (authKey) {
            return next.handle(this.getRequestWithCustomHeaders(request, authKey));
        }

        return next.handle(request);
    }

    private getRequestWithCustomHeaders(req: HttpRequest<any>, authKey: string) {
        return req.clone({
            headers: req.headers
                .set('Authorization', 'Bearer ' + authKey)
        });
    }

}
