import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import * as Sentry from "@sentry/angular" // for Angular 10/11 instead
import * as Sentry from '@sentry/angular-ivy';

Sentry.init({
    dsn: 'https://0323ab88c5c729b82bc1616990bbde35@o523212.ingest.sentry.io/4505748415643648',
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                'localhost',
                'https://development.autooptimo.com/api/',
                'https://autooptimo.com/api/',
                'https://autolog.test/api/',
                'http://127.0.0.1:8000/api/',
            ],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
