import { Serializer } from './serializer';
import { ArrayService } from '../../services/array.service';
import { TechnicalVisitItem } from '../resource-models/technical-visit-item';
import { TechnicalVisit } from '../resource-models/technical-visit';
import { Vehicle } from '../resource-models/vehicle';
import { VehicleSerializer } from './vehicle-serializer';
import { TechnicalVisitItemSerializer } from './technical-visit-item-serializer';
import { FileSerializer } from './file-serializer';
import { File } from '../resource-models/file';

export class TechnicalVisitSerializer implements Serializer {

    private arrayService = new ArrayService();
    private vehicleSerializer = new VehicleSerializer();
    private technicalVisitItemSerializer = new TechnicalVisitItemSerializer();
    private imageSerializer = new FileSerializer();

    fromJson(json: any): TechnicalVisit {
        return new TechnicalVisit(
            this.arrayService.getValue(json, 'id'),
            this.arrayService.getValue(json, 'type'),
            this.arrayService.getValue(json, 'dateOfWork'),
            this.arrayService.getValue(json, 'title'),
            this.arrayService.getValue(json, 'notes'),
            this.getItem(json),
            this.getData(json),
            this.arrayService.getValue(json, 'odometer'),
            this.getFiles(json),
            this.getTotalAmount(json),
        );
    }

    toJson(resource: TechnicalVisitItem): any {
        return {}
    }

    private getItem(json: any): Vehicle | undefined {
        return this.arrayService.getValue(json, 'vehicle') ? this.vehicleSerializer.fromJson(this.arrayService.getValue(json, 'vehicle')) : undefined;
    }

    private getData(json: any): TechnicalVisitItem[] | [] {
        const dataItemsArray = this.arrayService.getValue(json, 'items');
        return dataItemsArray === null ? [] : dataItemsArray.map((dataItemJson: any) => {
            return this.technicalVisitItemSerializer.fromJson(dataItemJson);
        });
    }

    private getFiles(json: any): File[] | [] {
        const dataItemsArray = this.arrayService.getValue(json, 'documents');
        return dataItemsArray === null ? [] : dataItemsArray.map((dataItemJson: any) => {
            return this.imageSerializer.fromJson(dataItemJson);
        });
    }

    private getTotalAmount(json: any): number {
        let total = 0;
        const dataItemsArray = this.arrayService.getValue(json, 'items');
        if (dataItemsArray === null) {
            return total;
        }
        dataItemsArray.map((dataItemJson: any) => {
            total += Number(dataItemJson.priceOfPart) + Number(dataItemJson.priceOfWork)
        });
        return total;
    }

}
