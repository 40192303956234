import { Resource } from "./resource";

export class VehicleCodeRequest extends Resource {
    driversCode: string;

    constructor(
        driversCode: string
    ) {
        super();
        this.driversCode = driversCode;
    }

}