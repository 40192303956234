<div class="site-layout" id="content-wrap">
    <nav>
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <ul class="pull-left">
                        <li class="d-none d-lg-inline-block">
                            <a class="nav-link" [routerLink]="'/'">
                                <img src="/assets/img/logo-full.svg" alt="Logo" width="300">
                            </a>
                        </li>
                        <li class="d-sm-inline-block d-lg-none">
                            <a class="nav-link" [routerLink]="'/'">
                                <img src="/assets/img/logo-short.svg" alt="Logo" width="60">
                            </a>
                        </li>
                    </ul>
                    <ul class="pull-right">
                        <ng-container *ngIf="isLoggedIn; else menuContainer">
                            <li class="d-none d-lg-inline-block">
                                <a class="nav-link fe-cursor-pointer text-white" [routerLink]="'/dashboard'">
                                    {{'nav.app.dashboard' | translate}}
                                </a>
                            </li>
                        </ng-container>
                        <ng-template #menuContainer>
                            <app-site-nav-content class="d-none d-lg-inline-block"></app-site-nav-content>
                        </ng-template>
                        <li>
                            <a class="nav-link">
                                <app-lang-switcher></app-lang-switcher>
                            </a>
                        </li>
                        <li class="d-sm-inline-block d-lg-none">
                            <a class="nav-link mobile-menu fe-cursor-pointer text-white" (click)="toggleSideNav()">
                                <i class="icon-menu1"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>

    <app-side-nav [sidenavTemplateRef]="navContent" [direction]="'left'" [navWidth]="300" [duration]="0.5">
    </app-side-nav>

    <!-- Container -->
    <router-outlet></router-outlet>
    <!-- Container -->

    <footer>
        <div class="footer text-center">
            <ul>
                <li>
                    <a class="nav-link" href="javascript:void()">
                        <span class="copyright">&#169;</span> 2022 Autooptimo
                    </a>
                </li>
                <li>
                    <a class="nav-link" [routerLink]="'/terms-and-conditions'">Terms of use</a>
                </li>
                <li>
                    <a class="nav-link" [routerLink]="'/contact'">Contact</a>
                </li>
            </ul>
        </div>
    </footer>

    <ng-template #navContent>
        <ng-container *ngIf="isLoggedIn; else pubMenu">
            <app-app-nav-content></app-app-nav-content>
        </ng-container>
        <ng-template #pubMenu>
            <app-site-nav-content></app-site-nav-content>
        </ng-template>
    </ng-template>

</div>