import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CookieService {
    isCookieSet(cookieName: string): boolean {
        return this.getCookie(cookieName) !== null;
    }

    deleteCookie(cookieName: string): void {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }

    private getCookie(name: string) {
        const dc = document.cookie;
        const prefix = name + '=';
        let begin = dc.indexOf('; ' + prefix);
        let end;
        if (begin == -1) {
            begin = dc.indexOf(prefix);
            if (begin != 0) return null;
        } else {
            begin += 2;
            end = document.cookie.indexOf(';', begin);
            if (end == -1) {
                end = dc.length;
            }
        }
        return decodeURI(dc.substring(begin + prefix.length, end));
    }

    deleteLaravelSessionCookies() {
        this.deleteCookie('XSRF-TOKEN');
        this.deleteCookie('autooptimo_session');
    }

}
