import { Resource } from "./resource";

export class ExpenseType extends Resource {
    override id: number;
    title: string;
    slug: string;
    icon: string | null;
    order: number;
    color: string;
    selected?: boolean;

    constructor(
        id: number,
        title: string,
        slug: string,
        icon: string | null,
        order: number,
        color: string,
        selected?: boolean
    ) {
        super();
        this.id = id;
        this.title = title;
        this.slug = slug;
        this.icon = icon;
        this.order = order;
        this.color = color;
        this.selected = selected;
    }

}