import { VehicleTransferRequest } from '../resource-models/vehicle-transfer-request';
import { Serializer } from './serializer';

export class VehicleTransferRequestSerializer implements Serializer {

    fromJson(json: any): any {
        return {}
    }

    toJson(resource: VehicleTransferRequest): any {
        return {
            first_name: resource.firstName,
            last_name: resource.lastName,
            email: resource.email
        }
    }

}
