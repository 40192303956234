import { Resource } from './resource';

export class GenericResponse extends Resource {
    message: string;

    constructor(
        message: string
    ) {
        super();
        this.message = message;
    }

}
