import {Serializer} from './serializer';
import { VehicleDeleteRequest } from '../resource-models/vehicle-delete-request';

export class VehicleDeleteRequestSerializer implements Serializer {

    fromJson(json: any): any {
        return {};
    }

    toJson(resource: VehicleDeleteRequest): any {
        return {
            password: resource.password
        }
    }
}
