import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { AuthGuard } from './guards/auth.guard';
import { GuestGuard } from './guards/guest.guard';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { OnboardingGuard } from './guards/onboarding.guard';
import { EmptyLayoutComponent } from './layouts/empty-layout/empty-layout.component';
import { FocusedLayoutComponent } from './layouts/focused-layout/focused-layout.component';

const routes: Routes = [
    {
        path: '',
        component: SiteLayoutComponent,
        children: [
            { path: '', pathMatch: 'full', redirectTo: '/auth/login' },
            // {
            //     path: 'terms-and-conditions',
            //     component: TermsAndConditionsComponent,
            //     pathMatch: 'full',
            // },
        ],
    },
    {
        path: '',
        component: AppLayoutComponent,
        children: [
            {
                path: 'dashboard',
                loadChildren: () =>
                    import('./pages/protected/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
                canActivate: [AuthGuard, OnboardingGuard],
            },
        ],
    },
    {
        path: '',
        component: FocusedLayoutComponent,
        children: [
            {
                path: 'dashboard-focused/vehicles/vehicle-details/:id/service-book',
                loadChildren: () =>
                    import(
                        './pages/protected/vehicle/vehicle-service-book/vehicle-service-book.module'
                    ).then((m) => m.VehicleServiceBookModule),
                canActivate: [AuthGuard, OnboardingGuard],
            },

            {
                path: 'dashboard-focused/expenses',
                loadChildren: () =>
                    import('./pages/protected/expense/expense.module').then(
                        (m) => m.ExpenseModule
                    ),
                canActivate: [AuthGuard, OnboardingGuard],
            },
            {
                path: 'dashboard-focused/vehicles/vehicle-details/:id/reminders',
                loadChildren: () =>
                    import(
                        './pages/protected/vehicle/vehicle-reminder/vehicle-reminder.module'
                    ).then((m) => m.VehicleReminderModule),
                canActivate: [AuthGuard, OnboardingGuard],
            },
        ],
        data: {
            hasBckgImage: true,
        },
    },
    {
        path: '',
        component: EmptyLayoutComponent,
        children: [
            {
                path: 'onboarding',
                loadChildren: () =>
                    import(
                        './pages/protected/onboarding/onboarding.module'
                    ).then((m) => m.OnboardingModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'auth',
                loadChildren: () =>
                    import('./pages/auth/auth.module').then(
                        (m) => m.AuthModule
                    ),
                canActivate: [],
            },
        ],
    },
    {
        path: '',
        component: EmptyLayoutComponent,
        children: [
            {
                path: 'onboarding-profile',
                loadChildren: () =>
                    import(
                        './pages/protected/onboarding-profile/onboarding-profile.module'
                    ).then((m) => m.OnboardingProfileModule),
                canActivate: [AuthGuard],
            },
        ],
    },
    {
        path: 'vehicle-overview',
        loadChildren: () =>
            import('./pages/public-vehicle/public-vehicle.module').then(
                (m) => m.PublicVehicleModule
            ),
    },
    {
        path: 'invites',
        component: FocusedLayoutComponent,
        loadChildren: () =>
            import('./pages/invites/invites.module').then(
                (m) => m.InvitesModule
            ),
        data: {
            hasBckgImage: true,
        },
    },
    {
        path: 'purchase-coins',
        component: FocusedLayoutComponent,
        loadChildren: () =>
            import('./pages/purchase-coins/purchase-coins.module').then(
                (m) => m.PurchaseCoinsModule
            ),
        data: {
            hasBckgImage: true,
        },
        canActivate: [AuthGuard],
    },
    {
        path: 'payment-success',
        component: EmptyLayoutComponent,
        loadChildren: () =>
            import('./pages/payment-status/payment-status.module').then(
                (m) => m.PaymentStatusModule
            ),
    },
    {
        path: 'payment-canceled',
        component: EmptyLayoutComponent,
        loadChildren: () =>
            import('./pages/payment-status/payment-status.module').then(
                (m) => m.PaymentStatusModule
            ),
    },
    {
        path: 'transfers',
        component: FocusedLayoutComponent,
        loadChildren: () =>
            import('./pages/transfers/transfers.module').then(
                (m) => m.TransfersModule
            ),
        data: {
            hasBckgImage: true,
        },
    },
    {
        path: 'car-cost-calculator',
        component: FocusedLayoutComponent,
        loadChildren: () =>
            import('./pages/car-cost-calculator/car-cost-calculator.module').then(
                (m) => m.CarCostCalculatorModule
            ),
        data: {
            hasBckgImage: true,
        },
    },
    {
        path: 'vin-decoder',
        component: EmptyLayoutComponent,
        loadChildren: () =>
            import('./pages/vin-decoder/vin-decoder.module').then(
                (m) => m.VinDecoderModule
            ),
        data: {
            hasBckgImage: true,
        },
    },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
