import { Serializer } from './serializer';
import { ArrayService } from '../../services/array.service';
import { ChartExpenseData } from '../resource-models/chart-expense-data';
import { ChartOdometerData } from '../resource-models/chart-odometer-data';
import { ChartExpenseDataSerializer } from './chart-expense-data-serializer';
import { NumberHelper } from 'src/app/helpers/number-helper';

export class ChartOdometerDataSerializer implements Serializer {
    private arrayService = new ArrayService();
    private chartExpenseDataSerializer = new ChartExpenseDataSerializer();

    fromJson(json: any): ChartOdometerData {
        return new ChartOdometerData(
            this.arrayService.getValue(json, 'name'),
            this.getItems(json),
            this.getNormalizedItemValues(json)
        );
    }

    toJson(resource: ChartExpenseData): any {
        return {};
    }

    private getItems(json: any): ChartExpenseData[] | [] {
        const dataItemsArray = this.arrayService.getValue(json, 'series');
        return dataItemsArray === null
            ? []
            : dataItemsArray.map((dataItemJson: any) => {
                  return this.chartExpenseDataSerializer.fromJson(dataItemJson);
              });
    }

    private getNormalizedItemValues(data: any): ChartExpenseData[] {
        if (!data['series'] || !data['series'].length) {
            return [];
        }
        data = data['series'].filter(
            (x: { [x: string]: number }) => x['value'] !== 0
        ).sort(
            (a: { value: number }, b: { value: number }) => a.value - b.value
        );

        const min = Math.min(...data.map((item: { value: any }) => item.value));
        const max = Math.max(...data.map((item: { value: any }) => item.value));

        const normalizedValueObjects = data.map(
            (item: { value: number; name: any }) => {
                const normalizedValue = NumberHelper.minMaxNormalization(
                    item.value,
                    min,
                    max
                );
                return {
                    name: item.name,
                    value: Math.round(normalizedValue * 100) / 100,
                    color: '',
                };
            }
        );

        for (let i = 0; i < normalizedValueObjects.length; i++) {
            const element: ChartExpenseData = normalizedValueObjects[i];
            const baseValue = element.value;
            const startValue = baseValue === 1 ? baseValue - 0.1 : baseValue;
            let endValue = baseValue;
            if (normalizedValueObjects[i + 1] !== undefined) {
                const nextValue = normalizedValueObjects[i + 1].value;
                endValue = nextValue === 1 ? nextValue - 0.1 : nextValue;
            }
            element.startValue = startValue;
            element.endValue = endValue;
        }
        return normalizedValueObjects;
    }
}
