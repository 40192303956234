import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { UserConfig } from '../resource-models/user-config';

export class UserConfigSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): UserConfig {
        return new UserConfig(
            this.arrayService.getValue(json, 'key'),
            this.arrayService.getValue(json, 'value')

        );
    }

    toJson(resource: UserConfig): any {
        return {
        }
    }
}
