import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { Permission } from '../resource-models/permission';

export class PermissionSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): Permission {
        return new Permission(
            this.arrayService.getValue(json, 'id'),
            this.arrayService.getValue(json, 'name')
        );
    }

    toJson(resource: Permission): any {
    }
}
