import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { UserSetting } from '../resource-models/user-setting';

export class UserSettingSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): UserSetting {
        return new UserSetting(
            this.arrayService.getValue(json, 'slug'),
            this.arrayService.getValue(json, 'title'),
            this.arrayService.getValue(json, 'description'),
            this.arrayService.getValue(json, 'value')

        );
    }

    toJson(resource: UserSetting): any {
        return {
        }
    }
}
