import { Resource } from "./resource";

export class ValidationMessage extends Resource {
    field: string;
    message: string;

    constructor(
        field: string,
        message: string
    ) {
        super();
        this.field = field;
        this.message = message;
    }

}