import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ErrorHandlerService } from '../services/error-handler.service';
import { VehicleService } from '../services/vehicle/vehicle.service';

@Injectable({
    providedIn: 'root'
})
export class OnboardingGuard implements CanActivate {

    constructor(
        private vehicleService: VehicleService,
        private errorHandler: ErrorHandlerService,
        private toastr: ToastrService,
        private router: Router
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return new Promise((resolve, reject) => {
            this.vehicleService.getVehicles().subscribe({                
                next: (vehicles) => {
                    if (vehicles.length) {
                        this.vehicleService.saveVehiclesToSession(vehicles);
                        resolve(true);
                        return;
                    }
                    resolve(false);
                    this.router.navigate(['/onboarding']);
                },
                error: (error) => {
                    this.toastr.error(this.errorHandler.getFirstUserMessage(error));
                    resolve(false);
                }
            })
        });
    }

}
