<div class="site-layout" id="content-wrap">

    <app-side-nav [sidenavTemplateRef]="navContent" [direction]="'left'" [navWidth]="300" [duration]="0.5">
    </app-side-nav>

    <div class="container-fluid">
        <div class="row">
            <div class="d-none d-lg-block col-lg-3 bg-white fe-no-padding sidebar-holder" [class.collapsed-sidebar-holder]="isCollapsed$ | async" (mouseenter)="onSidebarMouseEnter()" (mouseleave)="onSidebarMouseLeave()">
                <app-app-nav-content [isCollapsed]="isCollapsed$ | async"></app-app-nav-content>
                <app-app-nav-content [isBottomSideMenu]="true" [isCollapsed]="isCollapsed$ | async"></app-app-nav-content>
                <ul class="pull-left fe-full-width mt-3 mb-3" *ngIf="shouldShowAppVersion">
                    <li class="pull-left fe-full-width text-center fe-muted">
                        <a href="/assets/release_notes.json" target="_blank">
                            <small>v{{appVersion}}</small>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-md-12 col-lg-9 fe-m-t-small fe-m-b-medium">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <nav style="max-width: 1400px;">
                                <ul class="pull-left">
                                    <li class="d-sm-inline-block d-lg-none">
                                        <a class="nav-link mobile-menu fe-cursor-pointer" (click)="toggleSideNav()">
                                            <i class="icon-menu1"></i>
                                        </a>
                                    </li>
                                    <li class="fe-no-padding">
                                        <h3 class="bold-1 p-l-1" style="line-height: 60px;">
                                            <ng-container *ngIf="pageBreadCrumb$ | async as pageBreadCrum; else pageTitleTemplate">
                                                <button class="btn btn-sm btn-outline btn-quick-action p-0" (click)="onPageBreadCta(pageBreadCrum)"><i class="icon-cheveron-left"></i> {{pageBreadCrum.title | translate}}</button>
                                            </ng-container>
                                            <ng-template #pageTitleTemplate>
                                                <ng-container *ngIf="pageTitle$ | async as pageTitle">
                                                    {{pageTitle}}
                                                </ng-container>
                                            </ng-template>
                                        </h3>
                                    </li>
                                </ul>
                                <ul class="pull-right">
                                    <ng-container *ngIf="pageCtas$ | async as pageCtas">
                                        <ng-container *ngIf="pageCtas.length">
                                            <li *ngFor="let pageCta of pageCtas">
                                                <button class="btn btn-md btn-primary btn-outline text-black bg-white d-none-on-mobile"
                                                    (click)="openPageCta(pageCta)">
                                                    <i
                                                        class="{{pageCta.icon ? pageCta.icon : 'icon-plus'}}"></i>&nbsp;&nbsp;
                                                    {{pageCta.title | translate}}
                                                </button>
                                            </li>
                                        </ng-container>
                                    </ng-container>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <!-- Container -->
                <div class="container-fluid">
                    <div class="mt-4" style="max-width: 1400px;">
                        <router-outlet></router-outlet>
                    </div>
                </div>
                <!-- Container -->
            </div>
        </div>
    </div>

    <ng-template #navContent>
        <app-app-nav-content [isCollapsed]="isCollapsed$ | async"></app-app-nav-content>
        <app-app-nav-content [isBottomSideMenu]="true" [isCollapsed]="isCollapsed$ | async"></app-app-nav-content>
        <ul class="pull-left fe-full-width mt-3 mb-3" *ngIf="shouldShowAppVersion">
            <li class="pull-left fe-full-width text-center fe-muted">
                <a href="/assets/release_notes.json" target="_blank">
                    <small>v{{appVersion}}</small>
                </a>
            </li>
        </ul>
    </ng-template>

</div>
