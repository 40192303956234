import { Serializer } from './serializer';
import { ArrayService } from '../../services/array.service';
import { VehicleHistoryEntry } from '../resource-models/vehicle-history-entry';
import { Currency } from '../resource-models/currency';
import { CurrencySerializer } from './currency-serializer';

export class VehicleHistoryEntrySerializer implements Serializer {
    private arrayService = new ArrayService();
    private currencySerializer = new CurrencySerializer();

    fromJson(json: any): VehicleHistoryEntry {
        return new VehicleHistoryEntry(
            this.arrayService.getValue(json, 'id'),
            this.arrayService.getValue(json, 'type'),
            this.arrayService.getValue(json, 'title'),
            this.arrayService.getValue(json, 'comment'),
            this.arrayService.getValue(json, 'date'),
            this.arrayService.getValue(json, 'price'),
            this.arrayService.getValue(json, 'odometer'),
            this.arrayService.getValue(json, 'category'),
            this.arrayService.getValue(json, 'categoryColor'),
            this.getCurrency(json),
            this.arrayService.getValue(json, 'typeTranslated'),
            this.arrayService.getValue(json, 'timeAgo'),
        );
    }

    toJson(resource: VehicleHistoryEntry): any {
        return {}
    }

    private getCurrency(json: any): Currency | null {
        const dataItemJson = this.arrayService.getValue(json, 'currency');
        return dataItemJson ? this.currencySerializer.fromJson(dataItemJson) : null;
    }
}
