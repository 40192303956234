import { Resource } from "./resource";

export class UserSetting extends Resource {
    slug: string;
    title: string;
    description: string;
    value: string;

    constructor(
        slug: string,
        title: string,
        description: string,
        value: string
    ) {
        super();
        this.slug = slug;
        this.title = title;
        this.description = description;
        this.value = value;
    }

}