import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { VehicleCodeRequest } from '../resource-models/vehicle-code-request';

export class VehicleCodeRequestSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): VehicleCodeRequest {
        return new VehicleCodeRequest(
            this.arrayService.getValue(json, 'drivers_code')
        );
    }

    toJson(resource: VehicleCodeRequest): any {
        return {
            drivers_code: resource.driversCode
        }
    }
}
