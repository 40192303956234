import { File } from "./file";
import { Resource } from "./resource";
import { TechnicalVisitItem } from "./technical-visit-item";
import { Vehicle } from "./vehicle";

export class TechnicalVisit extends Resource {

    override id: number;
    type: string;
    dateOfWork: string;
    title: string;
    notes: string;
    vehicle: Vehicle | undefined;
    items: TechnicalVisitItem[];
    odometer?: number | null;
    documents?: File[];
    totalAmount?: number;

    constructor(
        id: number,
        type: string,
        dateOfWork: string,
        title: string,
        notes: string,
        vehicle: Vehicle | undefined,
        items: TechnicalVisitItem[],
        odometer?: number | null,
        documents?: File[],
        totalAmount?: number,
    ) {
        super();
        this.id = id;
        this.type = type;
        this.dateOfWork = dateOfWork;
        this.title = title;
        this.notes = notes;
        this.vehicle = vehicle;
        this.items = items;
        this.odometer = odometer;
        this.documents = documents;
        this.totalAmount = totalAmount;
    }

}