import { Resource } from "./resource";

export class FuelType extends Resource {
    key: string;
    name: string;

    constructor(
        key: string,
        name: string
    ) {
        super();
        this.key = key;
        this.name = name;
    }

}