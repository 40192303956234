import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { Role } from '../resource-models/role';

export class RoleSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): Role {
        return new Role(
            this.arrayService.getValue(json, 'id'),
            this.arrayService.getValue(json, 'name')
        );
    }

    toJson(resource: Role): any {
    }
}
