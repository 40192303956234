import { Currency } from "./currency";
import { File } from "./file";
import { Resource } from "./resource";

export class TechnicalVisitItem extends Resource {

    override id: number;
    title: string;
    comment: string;
    priceOfPart: string;
    priceOfWork: string;
    images: File[];
    currency: Currency | null;

    constructor(
        id: number,
        title: string,
        comment: string,
        priceOfPart: string,
        priceOfWork: string,
        images: File[],
        currency: Currency | null
    ) {
        super();
        this.id = id;
        this.title = title;
        this.comment = comment;
        this.priceOfPart = priceOfPart;
        this.priceOfWork = priceOfWork;
        this.images = images;
        this.currency = currency;
    }

}
