import { Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable, Subscription, filter } from 'rxjs';
import { PageBreadCrumb } from 'src/app/interfaces/page-bread-crumb';
import { PageCta } from 'src/app/interfaces/page-cta';
import { User } from 'src/app/models/resource-models/user';
import { AuthService } from 'src/app/services/auth.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-app-layout',
    templateUrl: './app-layout.component.html',
    styleUrls: [
        '../site-layout/site-layout.component.scss',
        './app-layout.component.scss',
    ],
})
export class AppLayoutComponent implements OnInit, OnDestroy {
    sidebarState: 'opened' | 'closed' = 'opened';
    user: User | undefined;
    pageTitle$: Observable<string | null> = this.navService.pageTitle$;
    pageCtas$: Observable<PageCta[]> = this.navService.pageCtas$;
    pageBreadCrumb$: Observable<PageBreadCrumb | null> = this.navService.pageBreadCrumb$;
    private routerSubscription!: Subscription;
    isCollapsed$: Observable<boolean> = this.navService.collapsedNavState$;
    isSideBarCollapsedInitially: boolean | undefined;
    hasNavigatedAway: boolean | undefined;
    isMediumScreen: boolean | undefined;
    appVersion = environment.appVersion;
    shouldShowAppVersion = environment.showAppVersion;

    constructor(
        private auth: AuthService,
        private navService: NavigationService,
        private userService: UserService,
        private router: Router,
        private location: Location
    ) {}

    @HostListener('window:resize', ['$event'])
    onResize($: any) {
        this.setIsMediumScreen();
        this.setIsCollapsed();
    }

    ngOnInit(): void {
        this.setIsMediumScreen();
        this.setIsCollapsed();
        this.getUser();
        this.routerSubscription = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: any) => {
                if (event instanceof NavigationEnd) {
                    this.navService.setPageTitle(null);
                    this.navService.setPageCtas([]);
                    this.hasNavigatedAway = true;
                }
            });
    }

    private setIsCollapsed(): void {
        const windowInnerWidth = window.innerWidth;
        if (windowInnerWidth <= this.navService.mdScreenMinWidth) {
            this.navService.setCollapsedNavState(false);
            return;
        }
        if (
            windowInnerWidth < this.navService.xlScreenMinWidth &&
            this.navService.isCollapsedNavStateForced
        ) {
            this.navService.setCollapsedNavState(true);
            return;
        }
        if (windowInnerWidth >= this.navService.mdScreenMaxWidth) {
            this.navService.setCollapsedNavState(false);
            return;
        }
        this.navService.setCollapsedNavState(true);
    }

    private setIsMediumScreen(): void {
        this.isMediumScreen =
            window.innerWidth >= this.navService.mdScreenMinWidth &&
            window.innerWidth < this.navService.mdScreenMaxWidth;
    }

    ngOnDestroy(): void {
        this.navService.setPageCtas([]);
        this.navService.setPageBreadCrumb(null);
        this.routerSubscription.unsubscribe();
    }

    private getUser() {
        this.userService.getUser().subscribe({
            next: (user) => {
                this.userService.setUser(user);
                this.user = user;
            },
        });
    }

    toggleSideNav() {
        this.navService.setShowNav(true);
    }

    openPageCta(cta: PageCta) {
        if (cta.delegateClickToParent) {
            this.navService.triggerCtaEmitter(cta);
            return;
        }
        this.router.navigate([cta.href]);
    }

    onSidebarMouseEnter() {
        this.isSideBarCollapsedInitially =
            this.navService.collapsedNavStateSubject.getValue() ||
            this.isMediumScreen;
        if (!this.isSideBarCollapsedInitially) {
            return;
        }
        this.navService.setCollapsedNavState(false);
        this.hasNavigatedAway = false;
    }

    onSidebarMouseLeave() {
        setTimeout(() => {
            if (
                this.isMediumScreen ||
                (!this.hasNavigatedAway && this.isSideBarCollapsedInitially)
            ) {
                this.navService.setCollapsedNavState(true);
            }
        }, 200);
    }

    onPageBreadCta(breadCta: PageBreadCrumb): void {
        if (breadCta.href) {
            this.router.navigate([breadCta.href]);
            return;
        }
        this.location.back();
    }

}
