import { Resource } from "./resource"

export class OneTimeToken extends Resource {
    token: string;

    constructor(
        token: string
    ) {
        super();
        this.token = token;
    }
}