import * as moment from "moment";

export class StorageHelper {

    public static setConfigItem(key: string, value: string): void {
        const storageKey = StorageHelper.getStorageKey();
        if (!localStorage.getItem(storageKey)) {
            localStorage.setItem(storageKey, JSON.stringify({ [key]: value }));
            return;
        }
        const data = Object.assign(JSON.parse(localStorage.getItem(storageKey) || ''), { [key]: value });
        localStorage.setItem(storageKey, JSON.stringify(data));
        return;
    }

    public static getConfigItem(item: string): string | null {
        if (!localStorage.getItem(StorageHelper.getStorageKey())) {
            return null;
        }
        const data = JSON.parse(localStorage.getItem(StorageHelper.getStorageKey()) || '');
        return item in data ? data[item] : null;
    }

    public static removeConfigItem(item: string): void {
        if (!localStorage.getItem(StorageHelper.getStorageKey())) {
            return;
        }
        const data = JSON.parse(localStorage.getItem(StorageHelper.getStorageKey()) || '');
        if (item in data) {
            delete data[item];
            localStorage.setItem(StorageHelper.getStorageKey(), JSON.stringify(data));
        }
    }

    public static getStorageKey(): string {
        return 'aot_preferences';
    }

    public static sessionStorageIsSupported(): boolean {
        try {
            const key = 'aot_lscheck';
            if (sessionStorage.getItem(key) && sessionStorage.getItem(key) === key) {
                return true;
            }
            sessionStorage.setItem(key, key);
            return true;
        } catch (e) {
            return false;
        }
    }

    public static localStorageIsSupported(): boolean {
        try {
            const key = 'aot_lscheck';
            if (localStorage.getItem(key) && localStorage.getItem(key) === key) {
                return true;
            }
            localStorage.setItem(key, key);
            return true;
        } catch (e) {
            return false;
        }
    }

    public static getItem(item: string): string | null {
        if (StorageHelper.sessionStorageIsSupported()) {
            let stringValue = sessionStorage.getItem(item);            
            if (stringValue !== null) {
                let value = JSON.parse(stringValue);                
                if (value.expirationDate) {
                    let expirationDate = new Date(value.expirationDate)
                    if (expirationDate > new Date()) {
                        return value.value || null;
                    } else {
                        StorageHelper.removeItem(item);
                        return null;
                    }
                }
                return sessionStorage.getItem(item) || null;
            }
        }
        return null;
    }

    public static setItem(item: string, value: string, expirationInMin: number | null = null): void {
        if (StorageHelper.sessionStorageIsSupported()) {
            if (expirationInMin) {
                var date = new Date();
                let expirationDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000) + (60000 * expirationInMin)).toISOString();
                let newValue = {
                    value: value,
                    expirationDate: expirationDate
                }
                sessionStorage.setItem(item, JSON.stringify(newValue));
                return;
            }
            sessionStorage.setItem(item, value);
        }
        return;
    }

    public static removeItem(item: string) {
        if (StorageHelper.sessionStorageIsSupported()) {
            sessionStorage.removeItem(item);
        }
        return;
    }

    public static hasEmptySettings(): boolean {
        if (StorageHelper.localStorageIsSupported()) {
            let preferences = localStorage.getItem('aot_preferences');
            return !preferences || Object.keys(JSON.parse(preferences)).length === 0 ? true : false;
        }
        return true;
    }

}
