import { Resource } from "./resource"

export class BearerToken extends Resource {
    access_token: string;

    constructor(
        access_token: string
    ) {
        super();
        this.access_token = access_token;
    }
}