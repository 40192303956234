import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { VehicleDriver } from '../resource-models/vehicle-driver';
import { VehiclePermission } from '../resource-models/vehicle-permission';
import { VehiclePermissionSerializer } from './vehicle-permission-serializer';

export class VehicleDriverSerializer implements Serializer {
    private arrayService = new ArrayService();
    private vehiclePermissionSerializer = new VehiclePermissionSerializer();

    fromJson(json: any): VehicleDriver {
        return new VehicleDriver(
            this.arrayService.getValue(json, 'id'),
            this.arrayService.getValue(json, 'email'),
            this.arrayService.getValue(json, 'firstName'),
            this.arrayService.getValue(json, 'lastName'),
            this.getItems(json)
        );
    }

    toJson(resource: VehicleDriver): any {
        return {}
    }

    private getItems(json: any): VehiclePermission | null {
        const dataItemJson = this.arrayService.getValue(json, 'permissions');
        return dataItemJson ? this.vehiclePermissionSerializer.fromJson(dataItemJson) : null;
    }

}
