import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import {Currency} from "../resource-models/currency";

export class CurrencySerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): Currency {
        return new Currency(
            this.arrayService.getValue(json, 'name'),
            this.arrayService.getValue(json, 'symbol'),
            this.arrayService.getValue(json, 'symbolNative'),
            this.arrayService.getValue(json, 'decimalDigits'),
            this.arrayService.getValue(json, 'rounding'),
            this.arrayService.getValue(json, 'code'),
            this.arrayService.getValue(json, 'namePlural')
        );
    }

    toJson(resource: Currency): any {
    }
}
