import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

@Injectable()
export class CustomValidatorService {
    requiredIfEmpty(controlName: string): ValidatorFn {
        return (formControl: AbstractControl) => {
            if (!formControl.parent) {
                return null;
            }
            const otherFormControl = formControl.parent.get(controlName);
            if (!otherFormControl) {
                return null;
            }
            if (otherFormControl.value === null) {
                return Validators.required(formControl);
            }
            return null;
        };
    }
    requiredWith(controlName: string): ValidatorFn {
        return (formControl: AbstractControl) => {
            if (!formControl.parent) {
                return null;
            }
            const otherFormControl = formControl.parent.get(controlName);
            if (!otherFormControl) {
                return null;
            }
            if (otherFormControl.value) {
                return Validators.required(formControl);
            }
            return null;
        };
    }
}
